import React, { useContext, useEffect, useRef, useState } from 'react';
import './ImgRedBannerTemplate.scss';
import { backgroundArtArray } from '../../assets/assetReference';
import { motion } from "framer-motion";
import { useInView } from 'react-intersection-observer';
import { GlobalContext } from '../../context/globalContext';
import { NavLink } from 'react-router-dom';

export default function ImgRedBannerTemplate(props) {
    const [ref, inView] = useInView({
        triggerOnce: props.triggerOnce,
        rootMargin: props.rootMargin || '0% 0px'
    });

    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, []);

    const videoRef = useRef(null);
    const [mediaLoaded, setMediaLoaded] = useState(false);
    const { isMobile } = useContext(GlobalContext);

    useEffect(() => {
        if (isMobile && videoRef && videoRef.current) {
            videoRef.current.muted = true;
            videoRef.current.playsInline = true;
            videoRef.current.playbackRate = 0.33;
            videoRef.current.play();
        }
    }, [videoRef.current]);

    useEffect(() => {
        if (videoRef && videoRef.current) {
            const handleVideoLoad = () => {
                setMediaLoaded(true);
                videoRef.current.muted = true;
                videoRef.current.playsInline = true;
                videoRef.current.playbackRate = 0.33;
                videoRef.current.play();
                console.log("video loaded");
            };

            videoRef.current.addEventListener('canplaythrough', handleVideoLoad);

            return () => {
                if (videoRef.current) {
                    videoRef.current.removeEventListener('canplaythrough', handleVideoLoad);
                }
            };
        }
    }, []);

    const handleImageLoad = () => {
        setMediaLoaded(true);
    };


    return (
        <div className={"img-red-banner-template-container img-background-template"}>
            <div className='background'>
                {props.backgroundImg.type === "video"
                    ?
                    <video playsinline autoplay muted loop ref={videoRef}>
                        <source src={props.backgroundImg.src} type="video/mp4" />;
                        Your browser does not support the video tag.
                    </video>
                    :
                    <img src={props.backgroundImg.src} alt={`${props.backgroundImg.alt}`} onLoad={handleImageLoad} />
                }

                {isMobile
                    ?
                    (mediaLoaded &&
                        <div className='title'>
                            <h1>
                                {typeof props.h1 === "string" ? <h1>{props.h1}</h1> : props.h1.map(i => {
                                    return <p>{i}</p>;
                                })}
                            </h1>
                        </div>
                    )
                    :
                    <motion.div
                        ref={ref}
                        initial={{ opacity: 0 }}
                        animate={inView ? { opacity: 1 } : {}}
                        transition={{ duration: props.duration || 1, delay: props.delay || 0, ease: 'easeOut' }}
                    >
                        {mediaLoaded &&
                            <div className='title'>
                                {typeof props.h1 === "string" ? <h1>{props.h1}</h1> : props.h1.map(i => {
                                    return <h1>{i}</h1>;
                                })}
                            </div>
                        }
                    </motion.div>
                }

            </div>
            <div className="main-content">
                <RedBanner content={props.redBannerContent} num={props.num} link={props.redBannerLink || ""} />
                {props.OtherContent}
            </div>
        </div>
    );
}

function RedBanner(props) {
    return (
        <div className="red-banner-container ">
            <img src={backgroundArtArray.logoArt} alt="2d offshore windmill art for background" />
            <div>
                {typeof props.content === "string"
                    ?
                    (props.num
                        ?
                        <p><b>{props.content.split(" ").slice(0, props.num).join(" ")}</b><span>&nbsp;{props.content.split(" ").slice(props.num).join(" ")}</span></p>
                        :
                        <p>{props.content}</p>
                    )
                    :
                    props.content.map((i, index) => {
                        if (index === 0) {
                            if (i.length === props.num) {
                                return <p><b>{i}</b></p>;
                            } else {
                                return <p><b>{i.split(" ").slice(0, props.num).join(" ")}</b><span>&nbsp;{i.split(" ").slice(props.num).join(" ")}</span></p>;
                            }
                        } else {
                            return <p className='s'>{i}</p>;
                        }
                    })
                }
                {props.link &&
                    <NavLink to={props.link} className="button a-no-decor">Learn more</NavLink>
                }
            </div>
        </div>
    );
}