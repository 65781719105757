import React, { useState } from 'react';
import './ContactUsPage.scss';
import ImgWhiteTemplate from '../../templates/ImgWhiteTemplate/ImgWhiteTemplate';
import { iconArray, londonPhotosArray, other, windmillPhotosArray } from '../../assets/assetReference';
import { companyInformation } from '../../data/content/content';
import { NavLink } from 'react-router-dom';
import { ContactEnquiry, ContactSubscribe } from "../../components/Contact/Contact";
import RedLineText from '../../components/RedLineText/RedLineText';
import { Helmet } from 'react-helmet';

export default function ContactUsPage() {
    return (
        <>
            <Helmet>
                <title>Contact Us | WeBridge</title>
                <meta
                    name="description"
                    content="WeBridge is a consulting firm bridging European offshore wind developers and international suppliers, providing expert services for the renewable energy industry. Learn more about how to contact us."
                />
                <meta
                    name="keywords"
                    content="WeBridge, wind energy bridge, offshore wind energy, contact us, linkedin, email, phone"
                />
            </Helmet>
            <div className={"contact-us-page-container"}>
                <ImgWhiteTemplate
                    backgroundImg={{ src: londonPhotosArray[1], alt: "a photo of london phone booth", type: "photo" }}
                    h1="Contact Us"
                    OtherContent={<OtherContent />}
                />
            </div>
        </>
    );
}

function OtherContent() {
    const [showSubscribeWindow, setShowSubscribeWindow] = useState(false);
    const [showWechatQR, setShowWechatQR] = useState(false);

    return (
        <div className="other-content">
            <div>
                <h2 className="subheading">Contact WeBridge</h2>
                <p className='special'> &#8212; Your Offshore Wind Industry Partner</p>
                <RedLineText
                    TextContent={<p>Whether you've established roots in the offshore wind industry or are gearing up to dive into it, we invite you to connect with our team. Let's explore how WeBridge can become your trusted partner and assist you in achieving your goals.</p>} />
            </div>
            <div>
                <h2 className="subheading">Contact Form</h2>
                <p>We understand the significance of effective communication in the fast-paced and demanding offshore wind industry. Let's make it effortless for you to share your thoughts and aspirations with us. Kindly use the contact form below, provide us with the necessary details, and we will respond to your inquiry promptly.</p>
                <ContactEnquiry />
            </div>
            <div>
                <h2 className="subheading">Contact Information</h2>
                <p>Alternatively, you can get in touch with us through the following contact details:</p>

                <div className='contact-information'>
                    <div >
                        <h3>Address</h3>
                        {companyInformation.address.split(";").map(i => {
                            return <p>{i}</p>;
                        })}
                    </div>
                    <div className="divider"></div>
                    <div>
                        <h3>Phone & Email</h3>
                        <p>{companyInformation.phone}</p>
                        <p><a href={`mailto:${companyInformation.email}`}>{companyInformation.email}</a></p>
                    </div>
                </div>
            </div>
            <div>
                <h2 className="subheading">Stay Connected</h2>
                <div className='flex stay-connected'>
                    <p>Stay up-to-date with the latest developments in the offshore wind industry and receive valuable insights from WeBridge by following our social media channels:</p>
                    <div className='flex two-icon'>
                        <a className="container-icon" href="https://www.linkedin.com/company/webridge-global/" target='_blank'><img src={iconArray.linkedin} /></a>
                        <div className="container-icon"
                            onMouseEnter={() => { setShowWechatQR(true); }}
                            onMouseLeave={() => { setShowWechatQR(false); }}
                            onClick={() => { setShowWechatQR(true); }}
                        >
                            <img src={iconArray.wechat} />
                            {showWechatQR && <div id='wechat-qr'><img src={other.wechatQR} /></div>}
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <h2 className="subheading">Subscribe to Our Newsletter</h2>
                <p>Subscribe to our newsletter to receive exclusive updates, insights, and special offers delivered straight to your inbox:</p>
                <div className={`subscribe ${showSubscribeWindow ? "no-height" : ""}`} onClick={() => { setShowSubscribeWindow(true); }}>
                    <button className="subscribe-buttom" >Subscribe Now</button>
                </div>
                <div className={`subscribe-window ${!showSubscribeWindow ? "no-height" : ""}`}>
                    <ContactSubscribe />
                    <span className="material-symbols-outlined close" onClick={() => { setShowSubscribeWindow(false); }}>expand_less</span>
                </div>
            </div>
            <p style={{ fontSize: "16px", lineHeight: "1.5" }}>WeBridge is dedicated to empowering stakeholders in the offshore wind industry through our tailored consulting services and strategic partnerships. We look forward to connecting with you and assisting you on your journey to remarkable success. Thank you for considering WeBridge as your reliable partner.</p>
        </div>
    );
}