import Insight from "../insightClass";
import React from "react";
import { insight19Media } from "../../../assets/assetReference";
import { ContactSubscribe } from "../../../components/Contact/Contact";
import { NavLink } from "react-router-dom";

export const insight019 = new Insight(
  "11 October 2024",
  {
    pageTitle:
      "WindEnergy Hamburg, 24-27th September 2024",
    metaDescription:
      "WeBridge Insights, WindEnergy Hamburg, 24-27th September 2024",
    keyWords:
      "European Offshore Wind Market development, WindEnergy Hamburg, 24-27th September 2024",
  },
  { src: insight19Media[0], alt: "document" },
  "WindEnergy Hamburg, 24-27th September 2024",
  "",
  `In any industry, there always seem to be some giants dominating the market. In the tech world we have Facebook, Apple, Nivida, Microsoft and Google. When it comes to offshore and onshore wind conferences, the dominant player is the WindEnergy conference – be it in Hamburg or Copenhagen. The number of exhibitors seems to increase year on year, and the number of attendees along with it.`,
  (
    <div>
      <strong>WeBridge Insights</strong>
      <h1>
        WindEnergy Hamburg, 24-27th September 2024
      </h1>
      <h2></h2>
      <p style={{ opacity: "0.4", marginTop: "-70px" }}>11 October 2024</p>

      <img src={insight19Media[0]}
        alt="photo at WindEnergy Hamburg"
        style={{ marginBottom: "48px" }} />

      <p>In any industry, there always seem to be some giants dominating the market. In the tech world we have Facebook, Apple, Nivida, Microsoft and Google. When it comes to offshore and onshore wind conferences, the dominant player is the WindEnergy conference – be it in Hamburg or Copenhagen. The number of exhibitors seems to increase year on year, and the number of attendees along with it.</p>

      <p>Depending on the segment of the industry that is of interest, there will be someone for you to converse with, and most likely there will be a session on the topic too. As our company, WeBridge is focusing on connecting Asian suppliers with European-based developers, there are relevant connections in all directions. The result of this is that you end up traversing what feels like halfway around the globe to meet developers and suppliers – back and forth between the many halls. A part of the challenge is to avoid getting bogged down in queues for free coffees and a plethora of free items with a corporate logo on it.</p>

      <p>With the scent of free coffee out of the way, the focus finally settles on networking and industry updates. As expected, I made many new connections and reconnected with perhaps even more old acquaintances. From all these encounters as well as walking past the many stands, there are three take-aways from the conference for me: the bottom-fixed projects will keep the industry busy for many years to come; the wave of floating wind projects is still some years out; and the amount of Chinese suppliers targeting the European market is firmly growing.</p>

      <p>Some years ago, in the euphoria of the floating wind, it seemed like the bottom-fixed offshore industry was just about to head into its twilight years – albeit with a proper last hurray. From the conversations that I had that is surely not the case. The bottom-fixed segment is pushing ahead, and new license rounds are met with interest by the developers  - if the terms are good enough.</p>

      <p>This renewed belief in the bottom-fixed segment is partly due to the lack of a sustained progression of the floating wind projects. There are a selected few projects scattered around the world in Korea, Italy, France and the UK (well done GreenVolt), but the across-the-board drive has failed to materialise. With this failure to create a firm, global demand consistency, the supply chain has understandably not ramped up either.</p>
      <p>I believe it will still be three to five years before the consistency we can find in the bottom-fixed wind is present in the floating wind segment, which would allow supply chain investments to flow more freely. Thus, to circle back again, we continue to put our faith in the bottom-fixed segment.</p>


      <p>Finally, as the Chinese supply chain feels more confident in their offerings in the home market and they see their margins being squeezed, the European market seems more enticing than ever. In addition, they have something which the European supply chain doesn’t have which is availability and arguably a competitive cost. The result is a growing attendance at the WindEnergy Conference.</p>

      <p>WeBridge has been set up to connect the best from the East with the needs of the West so we are continuing to look for additional suppliers that we can recommend to European developers. On the face value of the WindEnergy conference, it looks like we have positioned ourselves well for the years to come – may it be in bottom-fixed or in floating offshore wind.</p>


      <p>If you would like to find out more about the services WeBridge offer and how we could help with your offshore wind project, please <NavLink to="/contact-us">contact us</NavLink></p>








      <h3 style={{ marginTop: "200px" }}>Subscribe</h3>
      <ContactSubscribe />
    </div>
  )
);
