import React, { useEffect, useRef } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from '../layouts/Header/Header';
import Footer from '../layouts/Footer/Footer';
import AboutUsPage from '../pages/AboutUsPage/AboutUsPage';
import HomePage from '../pages/HomePage/HomePage';
import ContactUsPage from '../pages/ContactUsPage/ContactUsPage';
import ForDevelopersPage from '../pages/ForDevelopersPage/ForDevelopersPage';
import ForSuppliersPage from '../pages/ForSuppliersPage/ForSuppliersPage';
import WorkForUsPage from '../pages/WorkForUsPage/WorkForUsPage';
import { PoliciesCookies, PoliciesPrivacy, PoliciesTerms } from '../pages/Policies/Policies';
import BannerCookie from "../layouts/Banner/BannerCookie/BannerCookie";
import InsightsPage from '../pages/InsightsPage/InsightsPage';
import { insightRoutes } from '../routes/InsightRoutes';
import UnsubscribePage from '../pages/UnsubscribePage/UnsubscribePage';
import ForInsurersPage from '../pages/ForInsurersPage/ForInsurersPage';
import ForInvestorsPage from '../pages/ForInvestorsPage/ForInvestorsPage';

export default function AppRoutes() {
    return (
        <BrowserRouter>
            <Header />
            <main  >
                <Routes>
                    <Route path="/about-us" element={<AboutUsPage />} />
                    <Route path="/contact-us" element={<ContactUsPage />} />
                    <Route path="/services/for-developers" element={<ForDevelopersPage />} />
                    <Route path="/services/for-suppliers" element={<ForSuppliersPage />} />
                    <Route path="/services/for-investors" element={<ForInvestorsPage />} />
                    <Route path="/services/for-insurers" element={<ForInsurersPage />} />
                    <Route path="/work-for-us" element={<WorkForUsPage />} />
                    <Route path="/policies/terms-and-conditions" element={<PoliciesTerms />} />
                    <Route path="/policies/privacy-policy" element={<PoliciesPrivacy />} />
                    <Route path="/policies/cookies-policy" element={<PoliciesCookies />} />
                    <Route path="/insights" element={<InsightsPage />} />
                    {insightRoutes.map(route => {
                        return <Route key={route.path} path={route.path} element={route.element} />;
                    })}
                    <Route path="/unsubscribe" element={<UnsubscribePage />} />
                    <Route path="/" element={<HomePage />} />
                </Routes>
            </main>
            <BannerCookie />
            <Footer />
        </BrowserRouter>
    );
}