import Insight from "../insightClass";
import React from "react";
import { insight14Media } from "../../../assets/assetReference";
import { ContactSubscribe } from "../../../components/Contact/Contact";

export const insight014 = new Insight(
  "12 January 2024",
  {
    pageTitle:
      "What do ScotWind's local content requirements mean for international ​​suppliers?",
    metaDescription:
      "WeBridge Insights, What do ScotWind's local content requirements mean for international ​​suppliers?",
    keyWords:
      "European Offshore Wind Market development, What do ScotWind's local content requirements mean for international ​​suppliers?",
  },
  { src: insight14Media[0], alt: "document" },
  "What do ScotWind's local content requirements mean for international ​​suppliers?",
  "ScotWind's local content mandates do not prohibit international suppliers - and overseas involvement is likely to be mission critical.",
  `ScotWind is the first round of offshore wind leasing in Scottish waters
        for a decade, arranged by the Crown Estate Scotland (CES). A total of 20
        projects have been granted rights to build wind farms off the east and
        west coasts: ...`,
  (
    <div>
      <strong>WeBridge Insights</strong>
      <h1>
        What do ScotWind's local content requirements mean for international
        suppliers?
      </h1>
      <h2>
        ScotWind's local content mandates do not prohibit international
        suppliers - and overseas involvement is likely to be mission critical.
      </h2>
      <p style={{ opacity: "0.4", marginTop: "-70px" }}>12 January 2024</p>
      <h3>What is ScotWind?</h3>
      <p>
        ScotWind is the first round of offshore wind leasing in Scottish waters
        for a decade, arranged by the Crown Estate Scotland (CES). A total of 20
        projects have been granted rights to build wind farms off the east and
        west coasts: 13 sites with ​￼​floating foundations and seven sites with
        ￼​fixed-bottom foundations. It's estimated that ScotWind will provide a
        combined{" "}
        <a
          href="https://www.crownestatescotland.com/scotlands-property/offshore-wind/scotwind-leasing-round"
          target="_blank"
        >
          27.6GW
        </a>{" "}
        of generating capacity, with construction due to begin from the late
        2020s.
      </p>
      <h3>What are local content requirements - and what's their purpose?</h3>
      <p>
        Local content requirements are a mechanism used by governments to
        protect and enhance domestic economies. In the context of offshore wind
        development, which attract multi-billion-pound investment, local content
        requirements give developers rules on the extent to which they must
        engage local supply chains - whether that's purchasing from local
        companies, employing local staff or manufacturing in the local area.
      </p>
      <h3>What are ScotWind's local content requirements?</h3>
      <p>
        ScotWind was the first round of major offshore wind allocation in the UK
        that obligated applicants to provide an outline of supply chain
        commitments from the outset. Applicants were required to submit a Supply
        Chain Development Statement (SCDS), outlining the nature and location of
        supply chain activity across four key project stages:
      </p>
      <ul>
        <li>Development</li>
        <li>Manufacturing and fabrication</li>
        <li>Installation</li>
        <li>Operations</li>
      </ul>
      <br />
      <br />
      <br />
      <p>
        These initial SCDSs were used to provide a clear outline of supply chain
        requirements, and force focus on supply chain sustainability from the
        start. This sits against the backdrop of larger{" "}
        <a
          href="https://www.gov.uk/government/publications/offshore-wind-sector-deal/offshore-wind-sector-deal"
          target="_blank"
        >
          UK-wide content ambitions
        </a>
        : 60% UK content across the lifespan of offshore wind developments
        installed from 2030.
      </p>
      <p>
        Here's an example of SCDS commitments and ambitions for the West of
        Orkney Wind Farm.
      </p>

      <img
        src={insight14Media[1]}
        alt="Empire Engineering: ScotWind Local Content Summary"
        style={{ marginBottom: "48px" }}
      />
      <p className="fig-desc">Source: ScotWind Local Content Summary</p>
      <p>
        Developers can update their SCDS as their project progresses. But they
        must confirm a final SCDS to obtain a full seabed lease. The extent to
        which each developer's supply chain commitments have been achieved will
        be assessed at that point and, if appropriate, “contractual remedies”
        will be applied - ranging from financial penalties to lease revocation.
      </p>

      <img
        src={insight14Media[2]}
        alt="4C Offshore: ScotWind Market Focus Webinar"
        style={{ marginBottom: "48px" }}
      />
      <p className="fig-desc">
        Source: 4C Offshore: ScotWind Market Focus Webinar
      </p>

      <p>
        It's hoped that ScotWind will benefit Scottish businesses and
        communities for decades to come, while providing a major boost to UK
        clean energy production. Latest figures, taking into account all 20
        projects, show initial Scottish commitments total £28.8bn. That's an
        average investment in Scotland of £1.4bn per project.
      </p>
      <h3>Can international suppliers be part of ScotWind?</h3>
      <p>
        On paper the ScotWind local content requirements may seem exclusionary
        to international suppliers. But the reality is far more open. After all,
        the supply chain for successful offshore wind development typically
        spans international boundaries - and there's acceptance that developers
        must allocate budget for international partners.
      </p>
      <p>
        Here's what the current SCDS commitments project in terms of domestic
        and international spend.
      </p>

      <img
        src={insight14Media[3]}
        alt="Crown Estate Scotland: ScotWind Leasing"
        style={{ marginBottom: "48px" }}
      />
      <p className="fig-desc">
        <a
          href="https://www.crownestatescotland.com/scotlands-property/offshore-wind/scotwind-leasing-round"
          target="_blank"
        >
          Source: Crown Estate Scotland: ScotWind Leasing
        </a>
      </p>
      <p>
        Significant budget is available for European and Rest of World (ROW)
        suppliers. Specifically, there's broad recognition that the
        manufacturing and fabrication aspect of ScotWind's developments cannot
        be met locally, with a budget of almost £8bn allocated for ROW
        suppliers.
      </p>
      <img
        src={insight14Media[4]}
        alt="Crown Estate Scotland: ScotWind Leasing"
        style={{ marginBottom: "48px" }}
      />
      <p className="fig-desc">
        <a
          href="https://www.crownestatescotland.com/scotlands-property/offshore-wind/scotwind-leasing-round"
          target="_blank"
        >
          Source: Crown Estate Scotland: ScotWind Leasing
        </a>
      </p>
      <p>
        The lack of ability to use local content in this manufacturing and
        fabrication category will therefore be compensated by using local
        content in other areas of the ScotWind supply chain. No onus is placed
        on suppliers for delivering on local content objectives, which is
        squarely the responsibility of the developer. For instance,
        manufacturing and fabrication partners may be encouraged to set up new
        factories in Scotland - but this is not compulsory.
      </p>
      <h3>The business case for Chinese steel and fabrication</h3>
      <p>
        China - a global powerhouse of steel manufacture -is well-placed to
        assist with the development of ScotWind's allocated projects. The lack
        of capacity and supply chain difficulties in UK and European markets is
        recognised, and developers are increasingly looking to the
        well-integrated Chinese market - which has demonstrable ability as a
        world leader in offshore wind - as a reliable source of production
        support.
      </p>
      <p>
        Indeed, Chinese suppliers are already producing offshore wind foundation
        structures, including monopiles, jackets, and floating components, for
        the UK and European markets. The ambitious scale of the ScotWind project
        is such that the relationship between East and West can only grow more
        resilient and mutually prosperous.
      </p>
      <h3>WeBridge: a bridge between offshore wind markets</h3>
      <p>
        At WeBridge we help integrate Chinese suppliers into European offshore
        wind markets. With a comprehensive understanding of Chinese steel
        production and fabrication capabilities - coupled with time-tested
        experience across European offshore wind projects - we are perfectly
        placed to help ambitious Chinese businesses capitalise on European
        opportunities.{" "}
        <a
          href="https://windenergybridge.com/services/for-suppliers"
          target="_blank"
        >
          <b>Find out how here</b>
        </a>
        .
      </p>
      <h3>You may also be interested in: </h3>
      <p>
        &gt;&gt;&nbsp;
        <a
          href="https://windenergybridge.com/insights/offshore-wind-developments:-derisking-the-import-of-chinese-steel-"
          target="_blank"
        >
          Offshore wind developments: derisking the import of Chinese steel
        </a>
      </p>

      <h3 style={{ marginTop: "200px" }}>Subscribe</h3>
      <ContactSubscribe />
    </div>
  )
);
