import Insight from "../insightClass";
import React from "react";
import { insight1Media, windmillPhotosArray } from "../../../assets/assetReference";
import { ContactEnquiry, ContactSubscribe } from "../../../components/Contact/Contact";



export const insight001 = new Insight(
    "5 July 2023",
    {
        pageTitle: "Embracing New Entrants in the Offshore Wind Supply chain",
        metaDescription: "WeBridge Insights, Embracing New Entrants in the Offshore Wind Supply chain. Why European offshore wind developers need new entrants to succeed ",
        keyWords: "Offshore Wind Supply chain",
    },
    { src: windmillPhotosArray[9], alt: "a photo of many offshore windmill from far" },
    "Why Europe needs new entrants into the supply chain for Offshore Wind",
    "Advancing Europe's Offshore Wind Industry: Embracing New Entrants in the Offshore Wind Supply Chain",
    "Welcome to WeBridge Insights, where we delve into the renewable energy sector's significant developments. In this first issue, we shed light on a pivotal aspect of Europe's offshore wind industry - the urgent need for new entrants in the supply chain.  ",
    <div >
        <strong>WeBridge Insights</strong>
        <h1>Why Europe needs new entrants into the supply chain for Offshore Wind</h1>
        <h2>Advancing Europe's Offshore Wind Industry: Embracing New Entrants in the Offshore Wind Supply Chain</h2>
        <p style={{ opacity: "0.4", marginTop: "-70px" }}>5 July 2023</p>
        <h3>Introduction</h3>
        <p>Welcome to WeBridge Insights, where we delve into the renewable energy sector's significant developments. In this first issue, we shed light on a pivotal aspect of Europe's offshore wind industry - the urgent need for new entrants in the supply chain.</p>
        <p>As Europe forges ahead with its commitment to a sustainable future and strives to achieve ambitious green energy targets, it has become evident that a mere focus on enhancing the existing local supply chain is inadequate. We explore three compelling reasons why new entrants are vital in accelerating the growth and success of offshore wind energy.</p>
        <h3>Ambitious Renewable Targets and Supply-Demand Gap: A United Vision</h3>
        <p>The offshore wind industry in Europe is witnessing an extraordinary surge, primarily fuelled by ambitious decarbonization targets set by European nations. As of now, the region boasts a formidable 41 GW of offshore wind capacity, with bold plans to install 126 GW by 2030 and a staggering 207 GW by 2035. However, the existing supply chain appears to be grappling with the mounting demand, presenting challenges to keep pace with this soaring growth.</p>
        <img src={insight1Media[0]} alt="European Offshore Wind Capacity Forecast (MW) (Source: 4C Offshore)" />
        <p className="fig-desc"><a href="https://www.4coffshore.com/" target="_blank">European Offshore Wind Capacity Forecast (MW) (Source: 4C Offshore)</a></p>
        <p>Recent research conducted by <span style={{ color: "var(--color-main)" }}>RystadEnergy</span> has unveiled a concerning trend in the offshore wind industry. The demand for offshore wind installations is rapidly surpassing the current supply chain's capacity. Specifically, for the widely used Monopile foundation type, it is projected that by 2027, demand will outstrip the announced supply of monopile manufacturing. By 2030, the forecast indicates that foundation demand will exceed 3 million metric tons, more than double the current and planned capacity. Moreover, the situation for floating wind foundation supply is even more critical. The late 2020s show a robust demand forecast, which will exert significant pressure on the supply chain. To meet the projected demand, current capacities must grow five to sixfold by 2030. These figures highlight the urgent need for substantial growth and efficiency improvements in the offshore wind supply chain to accommodate the ambitious expansion plans of the industry.</p>
        <img src={insight1Media[1]} alt="Monopile manufacturing capacity and demand in Europe (Source: RystadEnergy)" />
        <p className="fig-desc"><a href="https://www.rystadenergy.com/" target="_blank">Monopile manufacturing capacity and demand in Europe (Source: RystadEnergy)</a></p>
        <img src={insight1Media[2]} alt="Floating wind foundation manufacturing capacity and demand in Europe (Source: RystadEnergy)" />
        <p className="fig-desc"><a href="https://www.rystadenergy.com/" target="_blank">Floating wind foundation manufacturing capacity and demand in Europe (Source: RystadEnergy)</a></p>
        <h3>The supply chain gap</h3>
        <p>Europe's transition to renewable energy is driven by a unified vision. However, to address the critical supply-demand gap and maintain a seamless flow of essential components and services, it is essential to leverage the potential of new international suppliers and visionary companies. These fresh entrants will play a pivotal role in enhancing the supply chain's capabilities, efficiently manufacturing and installing wind turbines, substructures, cables, and other crucial components required for offshore wind projects. With the momentum from these new entrants, Europe can rapidly scale up its offshore wind capacity, establishing new industry benchmarks, and reaffirming its commitment to combat climate change effectively. By embracing innovation and fresh perspectives, Europe is poised to solidify its position as a global leader in renewable energy and pave the way for a greener and more sustainable future.</p>
        <h3>Enhanced Competitiveness and Cost Reduction: Raising the Stakes</h3>
        <p>The escalating supply-demand gap presents significant obstacles to the progress of the offshore wind industry. Inadequate negotiating leverage with suppliers restricts developers from achieving substantial cost reductions. Consequently, meeting financial objectives and attaining decarbonization targets through further reductions in offshore wind expenditures becomes a formidable challenge.</p>
        <p>As new entrants join the offshore wind supply chain, healthy competition becomes the driving force behind cost reduction. This competition leads to a continuous improvement cycle, where companies innovate to stay ahead. This push for progress triggers advancements in manufacturing processes, materials, and fabrication techniques, elevating the overall quality of products and services.</p>
        <p>This positive cycle has far-reaching implications for the viability of deep-water wind projects. As costs decrease and quality improves, the Levelized Cost of Energy (LCOE) for offshore wind diminishes. This makes deep water projects more economically viable, enticing further investments in this segment of the industry. The ripple effect of this positive change ensures better returns on investment for project developers, encouraging more substantial and sustainable growth in the offshore wind sector.</p>
        <img src={insight1Media[3]} alt="LCOE Trends - Left: Fixed Foundation; Right: Floating Foundation (Source: 4C Offshore)" />
        <p className="fig-desc"><a href="https://www.4coffshore.com/" target="_blank">LCOE Trends - Left: Fixed Foundation; Right: Floating Foundation (Source: 4C Offshore)</a></p>
        <h3>Building a resilient supply chain: Energy Security</h3>
        <p>The Russia-Ukraine war has brought energy security to the forefront of Europe's concerns. Historically, Russia has been a major supplier of natural gas to many European countries, making them vulnerable to geopolitical tensions in the region. To reduce Europe’s reliance on fossil fuel imports and establish a robust renewable energy infrastructure, Europe needs to prioritize the development of a resilient offshore wind supply chain. Presently, the continent's offshore wind industry heavily relies on a handful of key suppliers, instrumental in driving its growth. However, to bolster the sector's resilience in the face of dynamic global uncertainties, embracing new entrants is vital.</p>
        <p>By welcoming new players across disciplines, including turbines, towers, fixed and floating  foundations, cables, and substations, Europe can fortify its energy security. A diverse supplier network ensures a steady and reliable flow of essential components and services, mitigating potential bottlenecks and supply shortages, enabling uninterrupted project execution, and safeguarding the industry against disruptions and uncertainties.</p>
        <p>As the world evolves, Europe's proactive approach in safeguarding its energy future not only fortifies its economic and environmental resilience but also sets a compelling example for global sustainability efforts.</p>
        <img src={insight1Media[4]} alt="Components of Energy Security (Source: SafetyCulture)" />
        <p className="fig-desc"><a href="https://safetyculture.com/topics/energy-security/" target="_blank">Components of Energy Security (Source: SafetyCulture)</a></p>
        <h3>Conclusions</h3>
        <p>Beyond the three critical reasons outlined earlier, there are numerous compelling rationales for Europe to embrace new entrants in the offshore wind market. These include diversification of expertise, accelerated technological advancements, and the potential to boost local economies and create jobs. By fostering a spirit of collaboration and progress, Europe invites all stakeholders to join in the resolute journey towards a greener and sustainable future. The path to a brighter tomorrow lies in our collective efforts to harness the vast potential of offshore wind and drive positive change in the renewable energy landscape.</p>
        <p className="last contact"><b><i>"Please don't hesitate to contact the WeBridge team (Email: <a href="mailto:contact@windenergybridge.com">contact@windenergybridge.com</a>) if you are interested in learning more about insights into the Chinese offshore wind supply chain."</i></b></p>
        <h3 style={{ marginTop: "200px" }}>Subscribe</h3>
        <ContactSubscribe />
    </div >
);