import Insight from "../insightClass";
import React from "react";
import { insight15Media } from "../../../assets/assetReference";
import { ContactSubscribe } from "../../../components/Contact/Contact";

export const insight015 = new Insight(
  "15 March 2024",
  {
    pageTitle:
      "Carbon Border Adjustment Mechanism (CBAM): what does it mean for importing offshore wind infrastructure from China?",
    metaDescription:
      "WeBridge Insights, Carbon Border Adjustment Mechanism (CBAM): what does it mean for importing offshore wind infrastructure from China?",
    keyWords:
      "European Offshore Wind Market development, Carbon Border Adjustment Mechanism (CBAM): what does it mean for importing offshore wind infrastructure from China?",
  },
  { src: insight15Media[0], alt: "document" },
  "Carbon Border Adjustment Mechanism (CBAM): what does it mean for importing offshore wind infrastructure from China?",
  "ScotWind's local content mandates do not prohibit international suppliers - and overseas involvement is likely to be mission critical.",
  `Both the EU and the UK have advanced plans for implementing a Carbon
        Border Adjustment Mechanism (CBAM). Essentially it's a tax on the
        importation of emissions-intensive goods. `,
  (
    <div>
      <strong>WeBridge Insights</strong>
      <h1>
        Carbon Border Adjustment Mechanism (CBAM): what does it mean for
        importing offshore wind infrastructure from China?
      </h1>
      <h2>
        The proposed Carbon Border Adjustment Mechanism poses tough questions
        relating to Europe's targets for offshore wind production. But for
        Chinese steel exporters, huge opportunities remain.
      </h2>
      <p style={{ opacity: "0.4", marginTop: "-70px" }}>15 March 2024</p>

      <h3>The ABC of the CBAM…</h3>
      <p>
        Both the EU and the UK have advanced plans for implementing a Carbon
        Border Adjustment Mechanism (CBAM). Essentially it's a tax on the
        importation of emissions-intensive goods. On paper, it disincentivises
        the importation of Chinese steel for offshore wind infrastructure. But
        the nuances are a little more complex. China is likely to remain crucial
        to Europe's ambitious offshore wind production targets.
      </p>
      <p>Let's take a closer look at CBAM and its impacts.</p>

      <h3>Before we get started…</h3>
      <p>
        Here at WeBridge we act as a knowledgeable interface to streamline
        collaboration between European offshore wind developers and
        international producers of offshore wind components.{" "}
        <a href="https://windenergybridge.com/about-us" target="_blank">
          <b>Find out more about what we do</b>
        </a>{" "}
        or{" "}
        <a href="https://windenergybridge.com/contact-us" target="_blank">
          <b>get in touch</b>
        </a>{" "}
        with any questions.
      </p>

      <h3>A quick introduction to the CBAM</h3>
      <p>
        Towards the end of 2023, both the EU and the UK announced plans to
        implement a Carbon Border Adjustment Mechanism (CBAM). In what would
        become the world's first carbon border tax, the CBAM would place a duty
        on companies importing emissions-intensive goods - such as electricity,
        hydrogen, steel, aluminium and cement. The CBAM is due to be phased in
        from 2026 in the EU, and from 2027 in the UK.
      </p>

      <h3>How will the CBAM work?</h3>
      <p>
        Under the proposed CBAM legislation, companies importing
        emissions-intensive materials and goods into the EU/UK will need to buy
        certification. The price of this certification will be determined by
        quarterly reporting on the volume of goods imported - and how polluting
        their manufacturing process is. In essence the tax/certification will be
        the difference between the price of domestic carbon allowances and the
        carbon penalties/taxes already paid in the country of production.
      </p>

      <p>
        Emissions measurement will likely encompass the whole of a product's
        supply chain: direct emissions released during the production of goods
        and indirect emissions from the production of electricity that is
        consumed during the production processes. There's also{" "}
        <a
          href="https://www.ics-shipping.org/news-item/carbon-mechanism-could-knock-12-off-eu-industrial-imports/"
          target="_blank"
        >
          discussion
        </a>{" "}
        about shipping and other downstream emissions being added to the scope
        of CBAM. Carbon credits will not be eligible to reduce the tax.
      </p>

      <h3>What are the intentions of the CBAM?</h3>
      <p>
        At this point it's worth noting: although the CBAM is in the advanced
        planning stage, nothing is set in stone. It's a provisional agreement in
        Europe and{" "}
        <a
          href="https://www.gov.uk/government/consultations/addressing-carbon-leakage-risk-to-support-decarbonisation/outcome/factsheet-uk-carbon-border-adjustment-mechanism"
          target="_blank"
        >
          under consultation
        </a>{" "}
        in the UK. Nevertheless the CBAM has four key ambitions.
      </p>
      <ul>
        <li>Reduce carbon leakage</li>
        <li>Increase the resilience and prosperity of domestic industry</li>
        <li>Work towards domestic carbon reduction targets</li>
        <li>Encourage global participation with carbon reduction</li>
      </ul>
      <p>Let's look at each in turn.</p>

      <h4>Reduce carbon leakage</h4>
      <p>
        Carbon leakage occurs when European businesses transfer
        emission-intensive production to countries with less stringent climate
        policies in order to avoid climate-related penalties in their home
        country. The CBAM hopes to disincentivise this practice by introducing a
        new financial levy on businesses transferring production outside the
        UK/EU.
      </p>

      <h4>Increase the resilience and prosperity of domestic industry</h4>
      <p>
        By reducing the incentive to outsource production to other countries,
        the CBAM naturally enhances focus on domestic production. The aim is to
        make it easier for European suppliers of goods such as steel to compete
        on cost, reducing the pricing disadvantage they have relative to non-EU
        suppliers.
      </p>

      <h4>Work towards domestic carbon reduction targets</h4>
      <p>
        The CBAM seeks to ensure emission reduction targets mandated by the EU
        are not negated by emissions increasing from non-EU countries. It's part
        of a raft of measures intended to reduce emissions by 55% by 2030 (Fit
        For 55) as Europe seeks to become the world's first carbon neutral
        continent by 2050.
      </p>

      <h4>Encourage global participation with carbon reduction</h4>
      <p>
        The CBAM forces European businesses to consider carbon emissions - and
        not just cost - in their procurement decisions. This, it's hoped, will
        encourage non-European suppliers to clean up their production processes.
        The cleaner a non-European supplier's production, the more attractive
        they become to UK and European businesses. In other words: the CBAM
        seeks to impose a commercial imperative on exporters to reduce their own
        carbon emissions.
      </p>

      <h3>
        What does the CBAM mean for Chinese exporters of offshore wind
        infrastructure?{" "}
      </h3>
      <p>
        The International Chamber of Shipping{" "}
        <a
          href="https://www.ics-shipping.org/news-item/carbon-mechanism-could-knock-12-off-eu-industrial-imports/"
          target="_blank"
        >
          anticipates
        </a>{" "}
        that the CBAM will reduce the combined import value of iron, steel,
        cement, fertilisers, aluminium, electricity and hydrogen into Europe by
        11.9% by 2030. Yet the CBAM does nothing to address the{" "}
        <a
          href="https://windenergybridge.com/insights/why-europe-needs-new-entrants-into-the-supply-chain-for-offshore-wind"
          target="_blank"
        >
          well-publicised shortfalls
        </a>{" "}
        in domestic steel production that are threatening to topple Europe's
        offshore wind development ambitions.
      </p>

      <p>
        European nations have{" "}
        <a
          href="https://windenergybridge.com/insights/why-europe-needs-new-entrants-into-the-supply-chain-for-offshore-wind"
          target="_blank"
        >
          bold plans
        </a>{" "}
        to move from 41 GW of offshore wind capacity to 207 GW by 2035. That
        requires steel. Lots of it. Yet{" "}
        <a
          href="https://windenergybridge.com/insights/why-europe-needs-new-entrants-into-the-supply-chain-for-offshore-wind"
          target="_blank"
        >
          research shows
        </a>{" "}
        that demand for offshore wind installations is rapidly surpassing the
        European supply chain&#39;s current capacity. There's an argument
        therefore that by making it more expensive to import steel from China,
        the CBAM further fuels the inflation in the UK and Europe that has been{" "}
        <a
          href="https://windenergybridge.com/insights/keeping-offshore-wind-developments-on-track-in-a-time-of-expensive-capital"
          target="_blank"
        >
          depressing the economic viability
        </a>{" "}
        of offshore wind development.
      </p>

      <p>
        There's a rational argument that in a time of domestic shortfall,
        European legislation should be making it easier to import Chinese steel,
        not harder. (And the CBAM is{" "}
        <a
          href="https://windenergybridge.com/insights/importing-steel-from-china:-duties-and-tariffs-payable"
          target="_blank"
        >
          far from the only levy
        </a>{" "}
        for developers to consider.) Contrast, for example, the CBAM with the
        <a
          href="https://en.wikipedia.org/wiki/Regional_Comprehensive_Economic_Partnership"
          target="_blank"
        >
          RCEP
        </a>{" "}
        - a new free trade agreement between China and 14 other Asia-Pacific
        nations. Disincentivising Chinese steel manufacturers to export into
        Europe seems misaligned with European offshore wind development
        ambitions at a time when steel is in demand globally.
      </p>

      <h3>Watch this space…</h3>
      <p>
        It would seem that demand for Chinese steel among European offshore wind
        developers isn't going anywhere. The commercial imperative remains
        robust. And even if the CBAM does come into force as planned, the tax
        will probably amount to a negligible additional payment for the
        importer.
      </p>

      <p>
        Yet this is a moving picture. The finer details of the CBAM are yet to
        be ironed out; under provisional agreement in the EU and under
        consultation in the UK. We will keep you posted as the situation
        develops. And if you have any questions, don't hesitate to{" "}
        <a href="https://windenergybridge.com/contact-us" target="_blank">
          get in touch
        </a>
        .
      </p>

      <h3>You may also be interested in:</h3>
      <p>
        &gt;&gt;&nbsp;
        <a
          href="https://windenergybridge.com/insights/how-steel-from-china-have-a-pivotal-role-to-play-in-european-offshore-wind"
          target="_blank"
        >
          How steel from China has a pivotal role to play in European offshore
          wind
        </a>
      </p>
      <p>
        &gt;&gt;&nbsp;
        <a
          href="https://windenergybridge.com/insights/offshore-wind-developments:-derisking-the-import-of-chinese-steel-"
          target="_blank"
        >
          Offshore wind development: derisking the import of Chinese steel
        </a>
      </p>
      <p>
        &gt;&gt;&nbsp;
        <a
          href="https://windenergybridge.com/insights/importing-steel-from-china:-duties-and-tariffs-payable"
          target="_blank"
        >
          Importing steel from China: duties and tariffs payable
        </a>
      </p>
      <p>
        &gt;&gt;&nbsp;
        <a
          href="https://windenergybridge.com/insights/keeping-offshore-wind-developments-on-track-in-a-time-of-expensive-capital"
          target="_blank"
        >
          Keeping offshore wind developments on track in a time of expensive
          capital
        </a>
      </p>

      <h3 style={{ marginTop: "200px" }}>Subscribe</h3>
      <ContactSubscribe />
    </div>
  )
);
