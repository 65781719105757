import React from "react";
import { insights } from "../data/insight/insightsExport";
import { Helmet } from "react-helmet";


function createInsightRoutes(list) {
    let routes = [];

    list.map(i => {
        routes.push({ path: `/insights/${i.title.toLowerCase().split(" ").join("-").replace("!", "")}`, element: i.InsightJSX });
    });
    return routes;
}


const insightRoutes = createInsightRoutes(insights);

export { insightRoutes }; 
