import Insight from "../insightClass";
import React from "react";
import { insight21Media } from "../../../assets/assetReference";
import { ContactSubscribe } from "../../../components/Contact/Contact";
import { NavLink } from "react-router-dom";

export const insight021 = new Insight(
  "28 October 2024",
  {
    pageTitle:
      "CWP2024 | A Golden October Showcasing the UK's “Wind” Expertise in China",
    metaDescription:
      "WeBridge Insights, CWP2024 | A Golden October Showcasing the UK's “Wind” Expertise in China",
    keyWords:
      "European Offshore Wind Market development, CWP2024 | A Golden October Showcasing the UK's “Wind” Expertise in China",
  },
  { src: insight21Media[0], alt: "uk-china-offshore-wind-partnership-reception" },
  "CWP2024 | A Golden October Showcasing the UK's “Wind” Expertise in China",
  "Original by Energy Group, Department for International Trade",
  `From 16-18th October, 2024, the UK's Department for Business and Trade (DBT) and Scottish Development International jointly organized the participation of 20 British companies in the China Wind Power 2024 (CWP2024) in Beijing. WeBridge was honoured to join the DBT delegation led by Lewis Neal, His Majesty's Trade Commissioner for China & Hong Kong.`,
  (
    <div>
      <strong>WeBridge Insights</strong>
      <h1>
        CWP2024 | A Golden October Showcasing the UK's “Wind” Expertise in China
      </h1>
      <h2>Original by Energy Group, Department for International Trade</h2>
      <p style={{ opacity: "0.4", marginTop: "-70px" }}>28 October 2024</p>

      <img src={insight21Media[0]}
        alt="photo at uk-china-offshore-wind-partnership-reception"
        style={{ marginBottom: "48px" }} />


      <p>From 16-18th October, 2024, the UK's Department for Business and Trade (DBT) and Scottish Development International jointly organized the participation of 20 British companies in the China Wind Power 2024 (CWP2024) in Beijing. WeBridge was honoured to join the DBT delegation led by Lewis Neal, His Majesty's Trade Commissioner for China & Hong Kong. This event marked a significant milestone for global energy dialogue and underscored the strategic importance of our initiatives.</p>

      <img src={insight21Media[1]}
        alt="speech photo"
        style={{ marginBottom: "48px" }} />

      <h3>The UK Reaffirms its Clean Energy Ambitions</h3>
      <p>At the CWP2024 opening ceremony, Ma Jieru, the UK's Deputy Head of Mission to China, delivered a speech highlighting the following:</p>
      <ul>
        <li>♻️ <b>Aiming to be a Clean Energy Superpower:</b> The UK's new government has set its sights on establishing the country as a “clean energy superpower” with an ambitious new energy action agenda.</li>
        <li>♻️ <b>Pioneering Net Zero Commitments:</b> The UK was the first major economy to make a legally binding commitment to net zero emissions by 2050. By 2022, the UK had reduced emissions by half compared to 1990 levels, while also achieving 80% economic growth.</li>
        <li>♻️ <b>Fully Decarbonizing by 2030:</b> The UK aims to have a completely decarbonized power system by 2030. To meet this target, offshore wind capacity must quadruple, including the rapid achievement of 5 GW of floating offshore wind, while onshore wind capacity will more than double within the next five years.</li>
        <li>♻️ <b>Increased Government Support for Offshore Wind Projects:</b> The UK government has raised this year's Contracts for Difference auction budget by 50%, allocating £1.1 billion for nine new offshore wind projects. This includes Europe's largest and second-largest wind farms located at Hornsea, England, and the future world's largest floating project, “Greenvolt,” near Scotland.</li>
      </ul>

      <img src={insight21Media[2]}
        alt="speech photo"
        style={{ marginBottom: "48px" }} />

      <ul>
        <li>♻️ <b>Great British Energy (GBE):</b> A state-owned energy company, GBE, will be established in Aberdeen, Scotland, with £8.3 billion in government funding to leverage £60 billion of private investment for the UK's energy transition.</li>
        <li>♻️ <b>UK-China Collaboration:</b> At the end of August, the UK's new Prime Minister, Keir Starmer, held talks with President Xi Jinping, where the two leaders discussed the importance of UK-China cooperation in climate change and the potential for increased trade and economic collaboration.</li>
        <li>♻️ <b>Deepening the Clean Energy Partnership:</b> In early September, Ed Miliband, the UK's new Secretary of State for Energy and Net Zero, spoke with Zhang Jianhua, China's Minister of the National Energy Administration. Both sides agreed to expand and deepen the UK-China clean energy partnership, with plans for an in-person energy dialogue in early 2025, marking the partnership's 10th anniversary.</li>
        <li>♻️ <b>UK-China Leadership in Clean Energy:</b> China ranks first globally in offshore wind capacity, with the UK in second. China leads in rapid new capacity installations and the development of larger, more efficient turbines, while the UK offers high-value services and innovative smart supply chain technology. The two countries' wind industries have distinct yet complementary strengths.</li>
      </ul>

      <img src={insight21Media[3]}
        alt="Aaron communicating"
        style={{ marginBottom: "48px" }} />

      <ul>
        <li>♻️ <b>Encouraging International Partnerships:</b> The UK seeks to attract more international partners and welcomes Chinese investment in the UK offshore wind market. The government is currently in discussions with leading Chinese wind companies about potential investments in UK manufacturing or assembly facilities.</li>
        <li>♻️ <b>UK's Offshore Wind Expertise:</b> As a pioneer in offshore wind, the UK has extensive expertise in commercial delivery. After nearly 25 years of offshore wind development, UK companies have accumulated significant experience in the operation and maintenance of fixed wind turbines. Having developed the world's first two commercial floating projects, UK companies have also gained valuable insights in floating offshore wind and are well-positioned to support China's floating wind projects.</li>
      </ul>

      <img src={insight21Media[4]}
        alt="event group photo"
        style={{ marginBottom: "48px" }} />



      <h3 style={{ marginTop: "200px" }}>Subscribe</h3>
      <ContactSubscribe />
    </div>
  )
);
