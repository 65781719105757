import React from 'react';
import './InsightsPage.scss';
import { backgroundArtArray } from '../../assets/assetReference';
import ImgWhiteTemplate from '../../templates/ImgWhiteTemplate/ImgWhiteTemplate';
import { insights } from '../../data/insight/insightsExport';
import Insight from '../../data/insight/insightClass';
import { Helmet } from 'react-helmet';

export default function InsightsPage() {
    return (
        <>
            <Helmet>
                <title>Insights | WeBridge</title>
                <meta
                    name="description"
                    content="WeBridge is a consulting firm bridging European offshore wind developers and international suppliers, providing expert services for the renewable energy industry. Learn more about our insights."
                />
                <meta
                    name="keywords"
                    content="WeBridge, wind energy bridge, offshore wind energy, offshore wind developers, offshore wind suppliers, insights, articles, analysis"
                />
            </Helmet>
            <div className={"insights-page"}>
                <ImgWhiteTemplate
                    backgroundImg={{ src: backgroundArtArray["insights"], alt: "newspaper background for decoration" }}
                    h1="WeBridge Insights"
                    OtherContent={<OtherContent />}
                />
            </div>
        </>
    );
}

function OtherContent() {
    return (
        <div className='other-content'>
            <p>WEBRIDGE INSIGHTS</p>
            {insights.map((i, index) => {
                const modifiedInsight = new Insight(
                    i.date,
                    i.seo,
                    i.img,
                    i.title,
                    i.sub,
                    i.preview,
                    i.jsx
                );
                modifiedInsight.heading = "h2";
                return modifiedInsight.previewJSX;
            })}
        </div>
    );
}