import React, { useEffect } from 'react';
import './Policies.scss';
import { companyInformation } from '../../data/content/content';
import { Helmet } from 'react-helmet';

export function PoliciesCookies() {
    return (
        <>
            <Helmet>
                <title>Cookies Policy | WeBridge</title>
                <meta
                    name="description"
                    content="WeBridge is a consulting firm bridging European offshore wind developers and international suppliers, providing expert services for the renewable energy industry. Learn more about our Cookies Policy."
                />
                <meta
                    name="keywords"
                    content="WeBridge, wind energy bridge, offshore wind energy, cookies policy, policies"
                />
            </Helmet>
            <Policy>
                <div className={"policy-container policies-cookies-container"}>
                    <h1>Cookies Policy</h1>
                    <p>Our website uses cookies and similar technologies, collectively referred to as cookies. By using our website and agreeing to this policy, you consent to our use of cookies in accordance with the terms of this policy.</p>
                    <ul>
                        <li>
                            <h2>About cookies</h2>
                            <p>A cookie is a file containing an identifier (a string of letters and numbers) that is sent by a web server to a web browser and is stored by the browser. The identifier is then sent back to the server each time the browser requests a page from the server.</p>
                            <p>Cookies can be used by web servers to identity and track users as they navigate different pages on a website and identify users returning to a website.</p>
                            <p>Cookies may be either “persistent” cookies or “session” cookies.</p>
                            <p>A persistent cookie consists of a text file sent by a web server to a web browser, which will be stored by the browser and will remain valid until its set expiry date (unless deleted by the user before the expiry date).</p>
                            <p>A session cookie, on the other hand, will expire at the end of the user session, when the web browser is closed.</p>
                        </li>
                        <li>
                            <h2>Our cookies</h2>
                            <p>We use both session cookies and persistent cookies on this website.</p>
                        </li>
                        <li>
                            <h2>Third party and analytics cookies</h2>
                            <p>When you use our website, you may also be sent third party cookies. Our advertisers / service providers may send you cookies. They may use the information they obtain from your use of their cookies:</p>
                            <ul>
                                <li>(a) to track your browser across multiple websites;</li>
                                <li>(b) to build a profile of your web surfing; and</li>
                                <li>(c) to target advertisements which may be of particular interest to you.</li>
                            </ul>
                            <p>In addition, we use Google Analytics to analyse the use of this website. Google Analytics generates statistical and other information about website use by means of cookies, which are stored on users' computers. The information generated relating to our website is used to create reports about the use of the website. Google will store this information. Google's privacy policy is available at <a href="http://www.google.com/privacypolicy.html" target='_blank'>http://www.google.com/privacypolicy.html</a></p>
                        </li>
                        <li>
                            <h2>Cookies and personal information</h2>
                            <p>Cookies do not contain any information that personally identifies you, but personal information that we store about you may be linked, by us, to the information stored in and obtained from cookies.</p>
                        </li>
                        <li>
                            <h2>Blocking cookies</h2>
                            <p>Most browsers allow you to refuse to accept cookies. For example:</p>
                            <ul>
                                <li>(a) in Internet Explorer (version 9) you can block cookies using the cookie handling override settings available by clicking “Tools”, “Internet Options”, “Privacy” and then “Advanced”;</li>
                                <li>(b) in Firefox (version 16) you can block all cookies by clicking “Tools”, “Options”, “Privacy”, selecting “Use custom settings for history” from the drop-down menu, and unticking “Accept cookies from sites”; and</li>
                                <li>(c) in Chrome (version 23), you can block all cookies by accessing the “Customise and control” menu, and clicking “Settings”, “Show advanced settings” and “Content settings”, and then selecting “Block sites from setting any data” under the “Cookies” heading. Blocking all cookies will, however, have a negative impact upon the usability of many websites.</li>
                            </ul>
                        </li>
                        <li>
                            <h2>Deleting cookies</h2>
                            <p>You can also delete cookies already stored on your computer. For example:</p>
                            <ul>
                                <li>(a) in Internet Explorer (version 9), you must manually delete cookie files (you can find instructions for doing so at <a href="http://support.microsoft.com/kb/278835" target='_blank'>http://support.microsoft.com/kb/278835</a>);</li>
                                <li>(b) in Firefox (version 16), you can delete cookies by clicking “Tools”, “Options”, “Privacy” and then “Show Cookies”, and then clicking “Remove All Cookies”; and</li>
                                <li>(c) in Chrome (version 23), you can delete all cookies by accessing the “Customise and control” menu, and clicking “Settings”, “Show advanced settings” and “Clear browsing data”, and then selecting “Delete cookies and other site and plug-in data” before clicking “Clear browsing data”. Again, doing this may have a negative impact on the usability of many websites.</li>
                            </ul>
                        </li>
                        <li>
                            <h2>Contact Us</h2>
                            <p>If you have any questions or concerns about these Terms and Conditions, please contact us at:</p>
                            <br />
                            <p><b>WeBridge</b></p>
                            {companyInformation.address.split(";").map(i => {
                                return <p>{i}</p>;
                            })}
                            <p>Telephone: {companyInformation.phone}</p>
                            <p>Email: <a href={`mailto:${companyInformation.email}`}>{companyInformation.email}</a></p>
                        </li>
                    </ul>
                </div>
            </Policy>
        </>
    );
}

export function PoliciesPrivacy() {
    return (
        <>
            <Helmet>
                <title>Privacy Policy | WeBridge</title>
                <meta
                    name="description"
                    content="WeBridge is a consulting firm bridging European offshore wind developers and international suppliers, providing expert services for the renewable energy industry. Learn more about our Privacy Policy."
                />
                <meta
                    name="keywords"
                    content="WeBridge, wind energy bridge, offshore wind energy, policies, privacy policy"
                />
            </Helmet>

            <Policy>
                <div className={"policy-container policies-privacy-container"}>
                    <h1>Privacy Policy</h1>
                    <p>Thank you for visiting windenergybridge.com. Wind Energy Bridge Limited (registered in England) and WeBridge Global Limited (registered in Hong Kong), together “WeBridge”, is the data controller. This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you interact with our website, services, or engage in business activities with us. We are committed to safeguarding your privacy and ensuring the security of your personal data.</p>
                    <ul>
                        <li>
                            <h2>Information We Collect</h2>
                            <ul>
                                <li>
                                    <h3>1.1 Personal Information:</h3>
                                    <p>WeBridge may collect personal information, such as your name, email address, postal address, phone number, job title, and company name when you voluntarily provide it to us. This information may be collected through our website, when you contact us, or during the course of our business relationship.</p>
                                </li>
                                <li>
                                    <h3>1.2 Usage Data:</h3>
                                    <p>When you visit our website, we may automatically collect certain information about your device and usage patterns. This may include your IP address, browser type, operating system, pages visited, and the referring website. We use this information to analyse trends, administer the website, and gather demographic information about our user base.</p>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <h2>Use of Information</h2>
                            <ul>
                                <li>
                                    <h3>2.1 Personal Information:</h3>
                                    <p>WeBridge may use the personal information we collect for the following purposes:</p>
                                    <ul>
                                        <li>To communicate with you and respond to your inquiries or requests.</li>
                                        <li>To provide the services you have requested, including consulting, business facilitation, and project management.</li>
                                        <li>To personalize your experience and improve our website and services.</li>
                                        <li>To send you marketing communications related to our services, but only if you have opted-in to receive such communications.</li>
                                    </ul>
                                </li>
                                <li>
                                    <h3>2.2 Usage Data:</h3>
                                    <p>WeBridge may use the usage data collected to analyse website trends, administer the website, and improve our services. This data helps us understand how visitors use our website, what content is popular, and how we can enhance user experience.</p>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <h2>Data Sharing and Disclosure</h2>
                            <ul>
                                <li>
                                    <h3>3.1 Third-Party Service Providers:</h3>
                                    <p>WeBridge may engage trusted third-party service providers to assist us in operating our business and delivering services to you. These providers may have access to your personal information only to perform specific tasks on our behalf and are obligated to keep your information confidential.</p>
                                </li>
                                <li>
                                    <h3>3.2 Legal Requirements:</h3>
                                    <p>We may disclose your personal information when required by law or if we believe that such disclosure is necessary to comply with a legal obligation, protect our rights, or investigate potential violations.</p>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <h2>Data Retention</h2>
                            <p>WeBridge will retain your personal information for as long as necessary to fulfil the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law. When we no longer need your information, we will securely dispose of it.</p>
                        </li>
                        <li>
                            <h2>Data Security</h2>
                            <p>We have implemented appropriate technical and organizational measures to protect your personal information against unauthorized access, loss, alteration, or destruction. However, please note that no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.</p>
                        </li>
                        <li>
                            <h2>Your Rights</h2>
                            <p>You have the right to access, correct, update, or delete your personal information. If you wish to exercise any of these rights, please contact us using the information provided at the end of this policy. We will respond to your request in accordance with applicable data protection laws.</p>
                        </li>
                        <li>
                            <h2>Changes to this Privacy Policy</h2>
                            <p>We reserve the right to modify or update this Privacy Policy at any time. Any changes will be effective immediately upon posting the revised version on our website. We encourage you to review this policy periodically to stay informed about how we collect, use, and protect your information.</p>
                        </li>
                        <li>
                            <h2>Contact</h2>
                            <p>If you have any questions or concerns about our Privacy Policy or the handling of your personal information, please contact us at:</p>
                            <p>Contact Details to exercise all relevant rights, queries of complaints please in the first instance contact us:</p>
                            <br />
                            <p><b>WeBridge</b></p>
                            {companyInformation.address.split(";").map(i => {
                                return <p>{i}</p>;
                            })}
                            <p>Telephone: {companyInformation.phone}</p>
                            <p>Email: <a href={`mailto:${companyInformation.email}`}>{companyInformation.email}</a></p>
                            <br />
                            <p>You can also contact the Information Commissioners Office:</p>
                            <br />
                            <p><b>Information Commissioner's Office</b></p>
                            <p>Wycliffe House</p>
                            <p>Water Lane, Wilmslow</p>
                            <p>Cheshire, SK9 5AF</p>
                            <p>Telephone: 0303 123 1113 </p>
                            <p><a href="https://ico.org.uk/global/contact-us" target="_blank">https://ico.org.uk/global/contact-us</a></p>
                        </li>
                    </ul>
                    <p>Please note that this Privacy Policy applies only to WeBridge and its website. It does not extend to any third-party websites or services that may be linked from our website. We encourage you to review the privacy policies of those third parties before providing them with any personal information.</p>
                </div>
            </Policy>
        </>
    );
}

export function PoliciesTerms() {
    return (
        <>
            <Helmet>
                <title>Terms & Conditions | WeBridge</title>
                <meta
                    name="description"
                    content="WeBridge is a consulting firm bridging European offshore wind developers and international suppliers, providing expert services for the renewable energy industry. Learn more about our Terms and Conditions."
                />
                <meta
                    name="keywords"
                    content="WeBridge, wind energy bridge, offshore wind energy, terms and conditions, policies"
                />
            </Helmet>
            <Policy>
                <div className={"policy-container policies-terms-container"}>
                    <h1>Terms and Conditions</h1>
                    <p>These Terms and Conditions ("Terms") govern your use of the website owned and operated by Wind Energy Bridge Limited (registered in England) and WeBridge Global Limited (registered in Hong Kong), together "WeBridge". By accessing or using our website, you agree to comply with these Terms. </p>
                    <ul>
                        <li>
                            <h2>Use of Website</h2>
                            <ul>
                                <li>
                                    <h3>1.1 Account Registration:</h3>
                                    <p>Some features of our website may require you to create an account. When registering, you must provide accurate, current, and complete information. You are solely responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</p>
                                </li>
                                <li>
                                    <h3>1.2 User Conduct:</h3>
                                    <p>You agree to use our website in compliance with applicable laws and regulations. You will not engage in any activity that may interfere with or disrupt the functioning of our website or infringe upon the rights of others. Prohibited activities include, but are not limited to, hacking, transmitting viruses or malicious code, or engaging in any form of unauthorized access.</p>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <h2>Intellectual Property</h2>
                            <ul>
                                <li>
                                    <h3>2.1 Ownership:</h3>
                                    <p>All content, materials, and intellectual property rights on our website, including but not limited to text, graphics, logos, images, videos, and software, are owned or licensed by WeBridge. These materials are protected by copyright, trademark, and other intellectual property laws.</p>
                                </li>
                                <li>
                                    <h3>2.2 Limited License:</h3>
                                    <p>Subject to these Terms, we grant you a limited, non-exclusive, non-transferable license to access and use our website for personal and non-commercial purposes. You may not modify, reproduce, distribute, sell, or exploit any content or materials from our website without our prior written consent.</p>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <h2>Disclaimer of Warranties</h2>
                            <ul>
                                <li>
                                    <h3>3.1 Website Availability:</h3>
                                    <p>We strive to ensure that our website is available and functioning smoothly at all times. However, we do not guarantee uninterrupted access to our website, and we cannot be held liable for any disruptions or technical issues that may arise.</p>
                                </li>
                                <li>
                                    <h3>3.2 Content Accuracy:</h3>
                                    <p>While we make reasonable efforts to provide accurate and up-to-date information on our website, we do not warrant the accuracy, completeness, or reliability of any content or materials. Any reliance you place on such information is at your own risk.</p>
                                </li>
                                <li>
                                    <h3>3.3 Third-Party Content:</h3>
                                    <p>Our website may contain links to third-party websites or resources. These links are provided for your convenience and do not imply our endorsement or responsibility for the content of such third-party websites. You acknowledge that we are not responsible for any damages or losses resulting from your use of third-party websites.</p>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <h2>Limitation of Liability</h2>
                            <p>To the fullest extent permitted by applicable law, WeBridge, its officers, directors, employees, and affiliates shall not be liable for any indirect, incidental, consequential, or punitive damages arising out of or in connection with your use of our website. This includes, but is not limited to, loss of profits, data, or other intangible losses, even if we have been advised of the possibility of such damages.</p>
                        </li>
                        <li>
                            <h2>Indemnification</h2>
                            <p>You agree to indemnify and hold WeBridge, its officers, directors, employees, and affiliates harmless from any claims, liabilities, damages, losses, costs, or expenses arising out of or in connection with your breach of these Terms or your use of our website.</p>
                        </li>
                        <li>
                            <h2>Governing Law and Jurisdiction</h2>
                            <p>These Terms shall be governed by and construed in accordance with the laws of the England and Wales. Any disputes arising out of or relating to these Terms or your use of our website shall be subject to the exclusive jurisdiction of the courts of the United Kingdom.</p>
                        </li>
                        <li>
                            <h2>Changes to Terms and Conditions</h2>
                            <p>We reserve the right to modify or update these Terms at any time without prior notice. Any changes will be effective immediately upon posting the revised version on our website. By continuing to use our website after any such changes, you accept and agree to the modified Terms.</p>
                        </li>
                        <li>
                            <h2>Contact Us</h2>
                            <p>If you have any questions or concerns about these Terms and Conditions, please contact us at:</p>
                            <br />
                            <p>WeBridge</p>
                            {companyInformation.address.split(";").map(i => {
                                return <p>{i}</p>;
                            })}
                            {/* <p>71-75, Shelton Street</p>
            <p>Covent Garden</p>
            <p>London, WC2H 9JQ</p>
            <p>United Kingdom</p> */}
                            <p>Telephone: {companyInformation.phone}</p>
                            <p>Email: <a href={`mailto:${companyInformation.email}`}>{companyInformation.email}</a></p>

                        </li>
                    </ul>

                </div>
            </Policy>
        </>
    );
}

function Policy(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    return props.children;
}