import React from 'react';
import './WorkForUsPage.scss';
import ImgWhiteTemplate from '../../templates/ImgWhiteTemplate/ImgWhiteTemplate';
import { londonPhotosArray, windmillPhotosArray } from '../../assets/assetReference';
import RedLineText from '../../components/RedLineText/RedLineText';
import { companyInformation } from '../../data/content/content';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default function WorkForUsPage() {
    return (
        <>
            <Helmet>
                <title>Work for Us | WeBridge</title>
                <meta
                    name="description"
                    content="WeBridge is a consulting firm bridging European offshore wind developers and international suppliers, providing expert services for the renewable energy industry. Learn more about how to join us and work for us."
                />
                <meta
                    name="keywords"
                    content="WeBridge, wind energy bridge, offshore wind energy, work for us, join us"
                />
            </Helmet>
            <div className={"work-for-us-page-container"}>
                <ImgWhiteTemplate
                    backgroundImg={{ src: londonPhotosArray[0], alt: "a photo of landscape of london" }}
                    h1="Work for Us"
                    OtherContent={<OtherContent />}
                />
            </div>
        </>
    );
}

function OtherContent() {
    return (
        <div className="other-content">
            <h2 className='subheading'>Join Us</h2>
            <RedLineText TextContent={
                <div>
                    <p>Interested in joining a diversified organisation with a mission to foster international collaboration in the offshore wind sector and contribute to a sustainable future? </p>
                    <br />
                    <p><b>We are always on the look out for talented individuals to join our team. </b></p>
                    <br />
                    <p>Please send your CV to <a href={`mailto:${companyInformation.email}`}>{companyInformation.email}</a>.</p>
                </div>
            }
            />
        </div>
    );
}