import { iconArray } from "../../assets/assetReference";

export const companyInformation = {
    address: "71-75, Shelton Street; Covent Garden; London, WC2H 9JQ; United Kingdom",
    email: "contact@windenergybridge.com",
    phone: "+44 (0)20 8036 4941"
};

export const companySellingPoint = [
    { icon: "experience", title: "Extensive Experience", desc: "A strong team with successful track record in European offshore wind market" },
    { icon: "industry", title: "Deep Industry Knowledge", desc: "A thorough understanding of European offshore wind market and developer’s requirements" },
    { icon: "relationship", title: "Strong Network", desc: "Established connections with key industry stakeholders, including developers and EPCI contractors" },
    { icon: "bridge", title: "Cultural Bridge", desc: "Effective communication and collaboration between International and European counterparts" }
];

export const bannerContent = {
    home: "We are WeBridge, connecting international offshore wind supply chains.",
    aboutUs: "WeBridge provides specialised services connecting international offshore wind suppliers with European developers.",
    forDevelopers: "By offering complementary specialist services to European offshore wind developers, we effectively bridge the demand and supply gaps in Europe, facilitating successful collaborations and contributing to the growth and development of the European offshore wind market, and a sustainable future.",
    forSuppliers: "We offer comprehensive business development services tailored to international suppliers' specific needs and objectives, ensuring a successful market entry and sustainable growth in the European offshore wind sector.",
    forInvestor: "WeBridge offers a comprehensive range of services to debt and equity investors in the offshore wind industry, designed to support their investing and lending decisions, risk assessment, and due diligence processes. ",
    forInsurers: "By leveraging our industry expertise, market insights, and network of key industry stakeholders, WeBridge bring a range of valuable services which empower insurers to navigate the offshore wind insurance landscape with confidence.",
};

export const ourVision = "A future where European offshore wind developers forge robust partnerships with international suppliers and service providers with unwavering confidence, closing capacity gaps and transforming infrastructure projects into resounding successes.";

export const ourMission = [
    { title: "Provide", desc: "Provide crucial links between European offshore wind farm developers and international fabricators" },
    { title: "Bring", desc: "Bring international cost-effective and high-quality products to the European market" },
    { title: "Foster", desc: "Foster international collaboration and contribute to a sustainable future" },
];

export const ourValues = [
    { img: iconArray.certificate, title: "Expertise", desc: "We possess deep knowledge and expertise in the European offshore wind market and international supply chains. Our team consists of industry professionals who understand the complexities and challenges of the industry, enabling us to provide strategic guidance and deliver effective solutions." },
    { img: iconArray.integrity, title: "Integrity", desc: "We conduct our business with the utmost integrity, maintaining honesty, transparency, and ethical practices at all times. We build trust with our clients, partners, and stakeholders through our actions and commitment to doing the right thing." },
    { img: iconArray.collaboration, title: "Collaboration", desc: "We believe in the power of collaboration and foster strong partnerships with international supply chain companies, offshore wind developers, and other stakeholders. We work together to create synergies, share knowledge, and achieve mutual success." },
];

export const servicesForDevelopers = [
    {
        title: "Market Supply-Demand Analysis",
        icon: "analysis",
        content: [
            "Provide valuable insights to European developers, enabling them to make informed decisions, identify potential opportunities for collaboration with international suppliers, and maximize the value of their offshore wind projects.",
            "Highlight the competitive advantages and unique offerings of international suppliers that can meet the market demand effectively.",
            "Suggest strategies for developers to optimise their supply chains, mitigate risks, and ensure timely project delivery.",
        ]
    },
    {
        title: "Supplier Identification and Evaluation",
        icon: "identification",
        content: [
            "Identify and evaluate suitable international suppliers that align with the developers' project requirements, quality standards, and budget constraints.",
            "Provide insights of selected suppliers, including their track record, technical capabilities, manufacturing capacities, and adherence to international standards and certifications.",
            "Facilitate communication, site visits, and negotiations between developers and suppliers to establish mutually beneficial partnerships.",
        ]
    },
    // { title: "Technology Collaboration" },
    {
        title: "Supply Chain Management and Optimisation",
        icon: "optimisation",
        content: [
            "Assist developers in optimising their supply chain by identifying opportunities for cost reduction, quality improvement, and streamlined project execution.",
            "Facilitate collaboration between developers and suppliers to ensure efficient delivery of components, equipment, and services throughout the project lifecycle.",
            "Support developers in managing logistics, procurement processes, and quality assurance to minimise risks and delays.",
        ]
    },
    // { title: "Project Financing and Investment" },
    {
        title: "Ongoing Relationship Management",
        icon: "ongoing",
        content: [
            "Act as a liaison between developers and suppliers, ensuring continuous collaboration, addressing any issues or disputes, and fostering long-term partnerships.",
            "Monitor project performance, conduct periodic evaluations, and provide feedback to optimise project outcomes and maximize the value delivered by suppliers.",
        ]
    },
];

export const servicesForSuppliers = [
    {
        title: "Market Entry Strategy",
        icon: "entry",
        content: [
            "Conduct a comprehensive analysis of the European offshore wind foundation market and develop an effective market entry strategy. ",
            "Establish a strong and differentiated brand presence in the European offshore wind market, effectively communicate its value proposition, and build trust and credibility with target customers and stakeholders.",

        ]
    },
    {
        title: "Marketing Campaigns",
        icon: "campaigns",
        content: [
            "Market campaigns play a vital role in establishing a strong market presence, attracting customers, building relationships, and achieving business objectives in the European offshore wind market. ",
            "Market campaigns are instrumental in creating brand awareness, generating project opportunities, and positioning your organisation as a trusted and reputable player in the European offshore wind market.",


        ]
    },
    {
        title: "Stakeholder Engagement",
        icon: "stakeholder",
        content: [
            "Effective engagement with clients and stakeholders will support your market entry, enhance project acceptance, and create a conducive environment for successful business operations.",
            "Build positive relationships, foster trust, and establish a strong reputation among key stakeholders in the European offshore wind market.",

        ]
    },
    {
        title: "Business Partnerships",
        icon: "partnerships",
        content: [
            "Strategically collaborate with local partners, leverage their market knowledge and resources, and establish a strong foothold in the European offshore wind market. ",
            "Establish partnerships with local supply chain businesses, contractors, and suppliers to enhance your market reach and facilitate project collaborations.",

        ]
    },
    {
        title: "Project Opportunities",
        icon: "opportunities",
        content: [
            "The specific project opportunity identification services will depend on unique business goals, target markets, and available resources. ",
            "Identify potential customers and projects for your offerings and capacities. ",
            "Build a database of potential leads, including contact information, project pipelines, project size, location, and procurement stage.",
            "Monitor and identify relevant offshore wind project tenders in the European market. Stay updated with industry news, tender announcements, and procurement platforms to identify opportunities that align with your capabilities and interests.",

        ]
    },
    {
        title: "Project Risk Assessment",
        icon: "risk",
        content: [
            "Conduct risk assessments for potential offshore wind project opportunities and carefully selected the most suitable projects to bid. ",
            "A thorough analysis of each identified risk, including the potential impact and likelihood of occurrence. ",
            "Mitigation strategies and recommendations will be provided to address and manage the identified risks effectively.",

        ]
    },
    {
        title: "Bidding Management",
        icon: "bidding",
        content: [
            "The bidding management services are designed to empower international suppliers by optimising their bidding strategies, enhancing proposal quality, and improving their competitive positioning. ",
            "We take a client-centric approach, tailoring our services to meet bidder’s specific unique needs and objectives. ",
            "Our experienced team of industry professionals will work closely with the bidding team, becoming an extension of the bidding team and providing guidance and support at every step of the bidding process, from tender identification to proposal preparation and contract negotiation.",
            "We combine our in-depth understanding of the European offshore wind foundation projects with a meticulous attention to detail, ensuring that suppliers submit competitive, compelling bids.",
            "By enhancing the competitiveness of suppliers' bids, we significantly increase the chances of securing valuable projects.",

        ]
    },
    {
        title: "Project Execution Support",
        icon: "execution",
        content: [
            "We offer project management support to suppliers, ensuring smooth project execution and delivery. ",
            "We can Access to European local knowledge and expertise, helping international suppliers adapt their products to European market requirements.",
            "Our dedicated project managers work closely with suppliers to coordinate logistics, manage timelines, and oversee quality control. ",
            "We facilitate effective communication between suppliers and other project stakeholders, helping them meet project requirements and achieve successful outcomes.",
        ]
    },
];

export const servicesForInsurers = [
    {
        title: "Risk Assessment and Underwriting Support",
        icon: "riskAssessmentSupport",
        content: [
            "WeBridge offers expertise in risk assessment and underwriting support specific to offshore wind projects. We conduct comprehensive risk assessments, evaluating factors such as project location, technology, construction, and operational risks. This information enables insurers to make informed decisions on coverage, pricing, and risk management strategies.",

        ]
    },
    {
        title: "Loss Control and Risk Mitigation",
        icon: "lossControl",
        content: [
            "WeBridge provides guidance on loss control and risk mitigation strategies for offshore wind projects. We assess project risks, identify potential vulnerabilities, and recommend risk reduction measures. By implementing effective loss control measures, insurers can minimize losses and improve the overall risk profile of insured projects."
        ]
    },
    {
        title: "Claims Management Support",
        icon: "claim",
        content: [
            "WeBridge assists insurers in managing claims related to offshore wind projects. Our experienced team can help assess the validity of claims, conduct investigations, and provide guidance on claim settlement. We work closely with insurers and other stakeholders to facilitate efficient and fair resolution of claims."
        ]
    },
    {
        title: "Market Entry Support",
        icon: "marketEntry",
        content: [
            "For insurers looking to enter the offshore wind insurance market, WeBridge offers market entry support. We provide insights into market dynamics, competition, and growth opportunities. We assist insurers in understanding the unique challenges and requirements of insuring offshore wind projects, helping them develop effective market entry strategies."
        ]
    },
    {
        title: "Market Insights and Intelligence",
        icon: "insights",
        content: [
            "WeBridge offers market insights and intelligence to insurers, keeping them updated on industry trends, regulatory changes, and emerging risks. Our expertise in the offshore wind sector allows insurers to stay ahead of the curve, anticipate market developments, and adjust their underwriting strategies accordingly."
        ]
    },
];
export const servicesForInvestor = [
    {
        title: "Project Due Diligence",
        icon: "projectDue",
        content: [
            "We conduct thorough due diligence on offshore wind projects to assess their feasibility, risks, and potential returns. Our experts analyse project documentation, financial models, technical specifications, environmental assessments, and regulatory compliance to provide investors with a holistic understanding of the project's viability."
        ]
    },
    {
        title: "Technical Due Diligence",
        icon: "techDue",
        content: [
            "Our experts conduct technical due diligence to evaluate the engineering aspects of offshore wind projects. This includes reviewing project design, equipment specifications, construction plans, and technical feasibility, providing investors with insights into project robustness."
        ]
    },
    {
        title: "Risk Assessment",
        icon: "riskAssessment",
        content: [
            "We assess the risks associated with offshore wind projects, including construction, operational, regulatory, financial, and environmental risks. Our risk assessment helps investors identify potential challenges and evaluate risk mitigation strategies, ensuring that lending decisions are well-informed and aligned with risk tolerance."
        ]
    },
    {
        title: "Independent Verification",
        icon: "verification",
        content: [
            "We offer independent verification of project information, financial models, and technical data provided by project developers. This verification enhances investors' confidence in the accuracy and reliability of project data."
        ]
    },
    {
        title: "Project Monitoring",
        icon: "monitoring",
        content: [
            "We provide ongoing project monitoring services, offering investors regular updates on project progress, risks, and milestones. This ensures that investors stay informed about the project's performance and can take timely actions if needed."
        ]
    },
    {
        title: "Market Entry Support",
        icon: "marketEntry",
        content: [
            "For financial institutions looking to enter the offshore wind market, WeBridge offers market entry support. We provide insights into market dynamics, competition, and growth opportunities. We assist investors in understanding the unique challenges and requirements of investing offshore wind projects, helping them develop effective market entry strategies."
        ]
    },
    {
        title: "Market Insights and Intelligence",
        icon: "insights",
        content: [
            "We keep investors informed about market trends, policy developments, technological advancements, and emerging opportunities in the offshore wind industry. Our insights help investors understand the market landscape and assess the project's alignment with industry dynamics."
        ]
    },
];