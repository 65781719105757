import React, { useContext, useState } from 'react';
import './ServiceBox.scss';
import './ServiceBoxMobile.scss';
import { GlobalContext } from '../../context/globalContext';

export default function ServiceBox(props) {
    const { isSmallScreen } = useContext(GlobalContext);

    return (
        <div className={`service-box-container`}>
            <img src={props.img.src} alt={props.img.alt} />
            <p className='title'>{props.item.title}</p>
            <div className="content">
                {props.item.content.map(i => {
                    return <p>{i}</p>;
                })}
            </div>
        </div>
    );
}


// export default function ServiceBox(props) {
//     const [open, setOpen] = useState(false);
//     const [hovered, setHovered] = useState(false);
//     const { isSmallScreen } = useContext(GlobalContext);

//     const handleMouseEnter = () => {
//         setHovered(true);
//     };

//     const handleMouseLeave = () => {
//         setHovered(false);
//     };

//     const handleOpenServiceBox = () => {
//         setOpen(true);
//         const header = document.querySelector("header");
//         header.style.filter = "brightness(0.7)";
//         if (isSmallScreen) header.style.zIndex = 0;
//         document.querySelector("body").style.overflow = "hidden";
//         document.querySelector("footer").style.zIndex = 0;

//     };

//     const handleCloseServiceBox = () => {
//         setOpen(false);
//         const header = document.querySelector("header");
//         document.querySelector("body").style.overflow = "visible";
//         header.style.filter = "brightness(1)";
//         if (isSmallScreen) header.style.zIndex = 1000000000;
//         document.querySelector("footer").style.zIndex = 10000;
//     };
//     return (
//         <div
//             className={`service-box-container ${hovered ? 'hovered' : ''}`}
//             onMouseEnter={handleMouseEnter}
//             onMouseLeave={handleMouseLeave}
//         >
//             <div className={`whole-overlay ${open && "visible"}`}>
//                 <div className="extended">
//                     <div className="title-container">
//                         <span className="material-symbols-outlined close-icon" onClick={handleCloseServiceBox}>
//                             close
//                         </span>
//                         <p className="title">{props.item.title}</p>
//                         <img src={props.img.src} alt={props.img.alt} />
//                     </div>
//                     <div className="content">

//                         {props.item.content.map(i => {
//                             return <p>{i}</p>;
//                         })}
//                     </div>
//                 </div>
//             </div>
//             <div className="folded" onClick={handleOpenServiceBox}>
//                 <img src={props.img.src} alt={props.img.alt} />
//                 <div className="overlay"></div>
//                 <p>{props.item.title}</p>
//             </div>
//         </div>
//     );
// }