import Insight from "../insightClass";
import React from "react";
import { insight13Media } from "../../../assets/assetReference";
import { ContactSubscribe } from "../../../components/Contact/Contact";



export const insight013 = new Insight(
    "29 December 2023",
    {
        pageTitle: "Going merchant: how offshore wind developers can boost income potential ",
        metaDescription: "WeBridge Insights, Going merchant: how offshore wind developers can boost income potential ",
        keyWords: "European Offshore Wind Market development, Going merchant: how offshore wind developers can boost income potential ",
    },
    { src: insight13Media[0], alt: "offshore wind developers" },
    "Going merchant: how offshore wind developers can boost income potential ",
    "Offshore wind developers can deploy a commissioning strategy that capitalises on high wholesale energy prices - without falling short of CfD obligations.",
    `Offshore wind developers are facing acute financial stressors. An unwelcome cocktail of global inflation, supply chain pressures and the high cost of borrowing has led to developer costs soaring by an estimated 40%.`,
    <div >
        <strong>WeBridge Insights</strong>
        <h1>Going merchant: how offshore wind developers can boost income potential </h1>
        <h2>Offshore wind developers can deploy a commissioning strategy that capitalises on high wholesale energy prices - without falling short of CfD obligations.</h2>
        <p style={{ opacity: "0.4", marginTop: "-70px" }}>29 December 2023</p>



        <p>Offshore wind developers are facing acute financial stressors. An unwelcome cocktail of global inflation, supply chain pressures and the high cost of borrowing has led to developer costs soaring by <a href="https://www.theguardian.com/business/2023/nov/10/uk-subsidies-for-offshore-windfarms-likely-to-increase-amid-rising-costs" target="_blank">an estimated 40%</a>. That puts the future of the offshore wind industry at risk and - with it - European green energy targets.</p>
        <p>To boost income potential, developers can take advantage of a commissioning mechanism that unlocks the ability to capitalise on high market prices for energy - without compromising existing CfD arrangements. And it's a strategy that national governments are starting to endorse. </p>
        <p>&gt;&gt; <a href="https://windenergybridge.com/insights/keeping-offshore-wind-developments-on-track-in-a-time-of-expensive-capital" target="_blank">Keeping offshore wind developments on track in a time of expensive capital</a></p>

        <h3>CfDs: secure energy prices for developers - but at what cost?</h3>
        <p>Contracts for Difference (CfDs) are designed to give developers security by guaranteeing a set price for each MWh of power they generate. This price, known as the strike price, is typically locked in for a period of 15 years - regardless of what happens to the wholesale electricity price.</p>
        <p>CfDs protect developers from financial losses when the market price for energy falls below the agreed strike price. But when the wholesale electricity price is high - as it has been since the Ukraine invasion - the fixed strike price leaves developers earning less than they could if they sold directly to the market.</p>
        <p>This disparity is currently highlighted starkly. At precisely the time <a href="https://windenergybridge.com/insights/keeping-offshore-wind-developments-on-track-in-a-time-of-expensive-capital" target="_blank">offshore wind developers' costs have soared</a>, the government has been reducing the maximum strike price for green energy. That's prompted complaints from developers that offshore wind is no longer economically viable.</p>
        <p>As such there were <a href="https://www.theguardian.com/environment/2023/sep/08/biggest-clean-energy-disaster-in-years-uk-auction-secures-no-offshore-windfarms" target="_blank">no bids for the development of offshore windfarms</a> in the government's latest clean energy auction (Allocation Round 5), despite five gigawatts of allocations being available - enough to power almost 8m homes per year. But there is a way that developers can have the best of both worlds: the potential rewards of selling at the market rate, with the security provided by CfDs.</p>
        <h3>Going merchant: capitalising on high wholesale prices</h3>
        <p>In the UK the CfD scheme typically allows a three-year buffer on the date of an offshore wind site's full commissioning. That means developers can enjoy up to three years of exposure to market prices by ‘going merchant' and selling to utility providers or commercial energy buyers before locking in to their CfD.</p>
        <p>And that could prove lucrative. During August 2022 in the UK, <a href="https://www.statista.com/statistics/589765/average-electricity-prices-uk/" target="_blank">spot day-ahead electricity prices</a> surged to almost £370/MWh - the highest month on record and almost ten times the level of August 2020. Contrast that with the recent CfD strike prices, which fell to £44/MWh in AR5.</p>
        <p>Allowing developers a grace period of selling to the market before locking into their CfD can boost income potential - encouraging economic security in the offshore wind sector at a time of high costs. It also incentivises developers to get sites operational as soon as they are able.</p>
        <p>That's why the practice of going merchant before commencing a CfD is becoming increasingly pervasive. In France, for example, the government is encouraging developers to go merchant for 18 months. Some developers are also looking to partition their energy production, locking a proportion into a CfD and selling the rest on the market.</p>

        <h3>What about power purchase agreements (PPAs)?</h3>
        <p>Another financial mechanism that developers can utilise to maximise profits is power purchase agreements (PPAs). These are contracts between developers and commercial buyers and lay out fixed terms of energy purchase - including quantity and price per unit. PPAs can range in duration, anything from one year to ten years or more, and give developers certainty that a pre-arranged quantity of energy will be purchased at an agreed price.</p>

        <h3>Maximising profit is a balancing act</h3>
        <p>In the race to increase economic resilience in the offshore wind sector, context is key. With CfD strike prices and wholesale energy prices in constant flux, commissioning strategies must be decided on a case-by-case basis.</p>
        <p>For many developers, the long-term price certainty offered by CfDs remains compelling. And following the failure of AR5, <a href="https://windenergybridge.com/insights/uk-government-boosts-offshore-wind-sector-with-much-higher-auction-prices" target="_blank">the government has raised the maximum strike price</a>. Fixed bottom offshore wind payments have risen by 66%, from £44/MWh to £73/MWh.  As for floating offshore wind, payments have risen by 52% - from £116/MWh to £176/MWh. A boon ahead of Allocation Round 6 (AR6), opening in March 2024.</p>
        <p>It's also important to remember that strike prices are based on 2012 prices - and when adjusted for inflation are worth more than the raw figure suggests. Nevertheless, as long as market prices remain high, finding a way to go merchant or lock in to a PPA can give developers a legitimate and much-needed strategy for boosting income potential.</p>
        <p>It all depends whether the price is right.</p>
        <h3>Who are we - and how can we help you?</h3>
        <p>Here at WeBridge we help offshore wind developers optimise costs and take advantage of untapped global supply chain efficiencies. So you can boost your income potential. <a href="https://windenergybridge.com/services/for-developers" target="_blank"><b>Find out more here</b></a>.</p>

        <h3>You may also be interested in: </h3>
        <p>&gt;&gt; <a href="https://windenergybridge.com/insights/keeping-offshore-wind-developments-on-track-in-a-time-of-expensive-capital" target="_blank">Keeping offshore wind developments on track in a time of expensive capital</a></p>
        <p>&gt;&gt; <a href="https://windenergybridge.com/insights/offshore-wind-developments:-derisking-the-import-of-chinese-steel-" target="_blank">Offshore wind developments: derisking the import of Chinese steel</a></p>



        <h3 style={{ marginTop: "200px" }}>Subscribe</h3>
        <ContactSubscribe />
    </div >
);