import Insight from "../insightClass";
import React from "react";
import { insight8Media } from "../../../assets/assetReference";
import { ContactSubscribe } from "../../../components/Contact/Contact";



export const insight008 = new Insight(
    "13 September 2023",
    {
        pageTitle: "Offshore wind developments: derisking the import of Chinese steel ",
        metaDescription: "WeBridge Insights, Offshore wind developments: derisking the import of Chinese steel  ",
        keyWords: "European Offshore Wind Market development, Offshore wind developments: derisking the import of Chinese steel ",
    },
    { src: insight8Media[0], alt: "offshore wind farm" },
    "Offshore wind developments: derisking the import of Chinese steel ",
    "With low cost and large availability, Chinese steel offers major benefits to European offshore wind developers. But importing from an unfamiliar market is not without risks… ",
    `Europe is approaching a major bottleneck in terms of offshore wind development capability. With ambitious targets for new developments, there is significant pressure on a manufacturing supply chain that is already experiencing major shortfalls and associative delays. Put simply, there's sizeable demand - and not enough capacity. `,
    <div >
        <strong>WeBridge Insights</strong>
        <h1>Offshore wind developments: derisking the import of Chinese steel </h1>
        <h2>With low cost and large availability, Chinese steel offers major benefits to European offshore wind developers. But importing from an unfamiliar market is not without risks… </h2>
        <p style={{ opacity: "0.4", marginTop: "-70px" }}>13 September 2023</p>


        <img src={insight8Media[0]} alt="offshore wind farm" />


        <p>Europe is approaching a major bottleneck in terms of offshore wind development capability. With ambitious targets for new developments, there is significant pressure on a manufacturing supply chain that is already experiencing major shortfalls and associative delays. Put simply, there's sizeable demand - and not enough capacity. </p>
        <p>&gt;&gt; <a href="https://windenergybridge.com/insights/why-europe-needs-new-entrants-into-the-supply-chain-for-offshore-wind" target="_blank">Why Europe needs new entrants into the supply chain for offshore wind  </a></p>
        <p>China is well positioned to help plug the gap between supply and demand - capable of providing low-cost, high-quality steel components at scale. Yet for many European developers, importing offshore wind components from China represents a leap into the unknown. And unknowns are so often anathema to confident business decisions. </p>
        <p>Here's an introduction to some of the key considerations to help derisk the import of Chinese steel. </p>

        <h3>Before we begin… </h3>
        <p>At WeBridge we streamline partnerships between European offshore wind developers and the Chinese supply chain. With fluency in both English and Mandarin and a deep understanding of offshore wind markets across Europe and China, our expertise is crucial to making sure developers complete new projects to spec while realising the cost-saving benefits available with Chinese steel. Here are some of the <a href="https://windenergybridge.com/services/for-developers" target="_blank">specific ways we can help.  </a></p>

        <h3>What are European developers concerned about? </h3>
        <p>European developers' lack of experience with the Chinese offshore wind industry leaves them significantly exposed - with little negotiating power. Key concerns include steel and fabrication quality, communication and cultural barriers, and project delivery. Below we will address each concern before highlighting additional areas to consider for productive collaboration with Chinese suppliers. </p>

        <h3>Steel and fabricator quality  </h3>
        <p>There remain latent, sticky concerns regarding the quality of Chinese steel and welding capabilities - in part fuelled by price points that are often significantly lower than European markets. Yet China's cost-competitiveness is fuelled not by a lack of quality, but by vast domestic production capabilities, an integrated supply chain and innovations that have increased production efficiency and propelled China to become the world's leading steel producer.    </p>
        <p>China has a vast 30.2 GW of fully operational offshore wind developments, with a further 6.2 GW under construction as of Q2 2023.* In short: manufacturing components for offshore wind developments isn't just something Chinese fabricators are comfortable with, they are the world leaders.  </p>
        <p style={{ fontSize: "0.7em" }}>* Source: 4C Offshore: Global Market Overview - Q2 2023.  </p>
        <p>Concerns around the quality of Chinese steel and fabrication can perhaps be further allayed by recognising that Chinese steel is already used in major offshore oil and gas production facilities for operators including BP, Shell and Equinor. Top Chinese fabricators are already familiar with working to precise European and North American standards.  </p>
        <p>Yet it's also true to say that Chinese fabricators aren't all created equal. In a market with over 100 suppliers, quality is of course variable. Due diligence is crucial when searching for a fabrication partner - and here are the key areas to consider.  </p>

        <ul>
            <li>Past project experience </li>
            <li>Fabrication process of various structures   </li>
            <li>HSE management system documentations  </li>
            <li>Quality management documentation  </li>
            <li>Quality assurance leaders' background and experience   </li>
            <li>Yard capacities  </li>
            <li>Yard facilities   </li>
            <li>Size of quality team and capabilities   </li>
            <li>Technical authority's background and experience   </li>
            <li>Load-out and shipping arrangement   </li>
        </ul>

        <h3>Communication and cultural barriers   </h3>
        <p>Mandarin is deeply unfamiliar to most European natives. Yet language is not the barrier many developers expect. Chinese steel is already exported to international markets and many steel suppliers and fabricators speak very good English.  </p>
        <p>A more significant issue lies within the differences in business culture.  </p>
        <p>Chinese businesses are not always forthcoming in proving how they can meet the specifications of a project. Fear of not winning a contract can sometimes lead fabricators to over promise on what they can offer. Execution teams can fail to communicate the nuances of key project deliverables and sometimes - having won a contract - a business may begin to try and negotiate changes to the production roadmap.  </p>
        <p>To mitigate this it's essential to look for a track record of delivering projects to precise specification - on time and on budget. Seek an explanation of how the project scope will be followed and communicated by execution teams. Always have a plan in place from the outset to monitor production and mitigate against the risk of delays. And if possible have someone on the ground in China to oversee the project.  </p>
        <p>Or let us act as an intermediary.  </p>
        <p>Fluent in both Mandarin and English, we can make sure the scope of your project is passed on with 100% clarity. We understand critical control points and can monitor your project - on the ground if needed - to make sure all specs are met without compromise. <a href="https://windenergybridge.com/services/for-developers" target="_blank">Find out more</a>. </p>

        <h3>Project delivery: import duty and tariffs  </h3>
        <p>When importing from China it's essential to understand your liability in terms of import duties and tariffs. These are variable and conditional on the type of assets you are importing and in what volume. However it's important to note that even with the necessary import duties, Chinese steel can still offer significant cost efficiencies to European developers. </p>
        <p>Before shipping it's the importer's obligation to make sure the correct &lt;&lt;<a href="https://windenergybridge.com/insights/importing-steel-from-china:-duties-and-tariffs-payable" target="_blank">tariff codes</a>&gt;&gt; are applied and all paperwork is in place - such as commercial invoice, packing list and bill of lading. Failure to do so can incur fines and penalties as well as resulting in customs holding on to or rejecting imported goods. </p>
        <p>&gt;&gt; <a href="https://windenergybridge.com/insights/importing-steel-from-china:-duties-and-tariffs-payable" target="_blank">Importing steel from China: duties and tariffs payable</a></p>

        <h3>Fluctuating exchange rates  </h3>
        <p>China's currency, the Chinese Renminbi (RMB), has a history of volatility. That can make it difficult to accurately forecast project costs. However we have partners that can mitigate cross-currency fluctuations when importing goods from China. So you can operate with more certainty.  </p>

        <h3>Access to capital </h3>
        <p>For European developers there are well-documented difficulties with the rising cost of access to finance, with the weighted average cost of capital (WACC) for renewable projects having <a href="https://www.cornwall-insight.com/press/soaring-cost-of-capital-threatens-to-derail-expansion-of-renewable-energy-projects/" target="_blank">increased</a> by about 4% since early 2021. Importing from a new market can add to the uncertainties that are already driving the rising cost of capital. By working with you to understand the Chinese offshore wind market, we can derisk projects, bring more certainty to your strategy and create a tighter proposal when in search of capital.   </p>

        <h3>Schedule a conversation </h3>
        <p>If you would like to know more about how we could work with you to streamline collaboration with Chinese suppliers, <a href="https://windenergybridge.com/contact-us" target="_blank">get in touch</a> and we can arrange a call.  </p>

        <h3 style={{ marginTop: "200px" }}>Subscribe</h3>
        <ContactSubscribe />
    </div >
);