import Insight from "../insightClass";
import React from "react";
import { insight20Media } from "../../../assets/assetReference";
import { ContactSubscribe } from "../../../components/Contact/Contact";
import { NavLink } from "react-router-dom";

export const insight020 = new Insight(
  "21 October 2024",
  {
    pageTitle:
      "Floating Offshore Wind, Aberdeen, 9-10th October 2024",
    metaDescription:
      "WeBridge Insights, Floating Offshore Wind, Aberdeen, 9-10th October 2024",
    keyWords:
      "European Offshore Wind Market development, Floating Offshore Wind, Aberdeen, 9-10th October 2024",
  },
  { src: insight20Media[0], alt: "document" },
  "Floating Offshore Wind, Aberdeen, 9-10th October 2024",
  "",
  `There is no doubt the Granite City is trying its best to rebrand itself as the green powerhouse of the United Kingdom. The lion's share of the ScotWind projects are at a striking distance from the city that made its second mark on the world as the capital of oil & gas in Europe. Now expectations are high for a transition to renewable energy. With the central European cities having a stronghold on the bottom-fixed segment of offshore wind, the aim is to become the floating wind capital of Europe, if not the world.`,
  (
    <div>
      <strong>WeBridge Insights</strong>
      <h1>
        Floating Offshore Wind, Aberdeen, 9-10th October 2024
      </h1>
      <h2></h2>
      <p style={{ opacity: "0.4", marginTop: "-70px" }}>21 October 2024</p>

      <img src={insight20Media[0]}
        alt="photo at Floating Offshore Wind, Aberdeen"
        style={{ marginBottom: "48px" }} />


      <p>There is no doubt the Granite City is trying its best to rebrand itself as the green powerhouse of the United Kingdom. The lion's share of the ScotWind projects are at a striking distance from the city that made its second mark on the world as the capital of oil & gas in Europe. Now expectations are high for a transition to renewable energy. With the central European cities having a stronghold on the bottom-fixed segment of offshore wind, the aim is to become the floating wind capital of Europe, if not the world.</p>


      <p>As such, the Floating Offshore Wind Conference & Exhibition has been central to the city's strive to become the floating wind capital of the world. For the last couple of years, former individuals of the oil & gas industry and the people who brought the wind industry offshore in Europe have been gathering in Aberdeen to explore the opportunities and challenges of deploying floating wind in the UK and, in due course, globally.</p>

      <p>As with most conferences, the attendees can be predominately divided into two: the developers and the suppliers. However, to the credit of the developers, at the Floating Offshore Wind conference they do attend the exhibition with knowledgeable and curious individuals who like to understand what the suppliers can offer in terms of services. With the industry still being immature, we have to find a way to work together, so this mutual engagement is not only a nice feature but also essential for the industry to progress.</p>

      <p>The backdrop to the conference was the award of Contract for Difference to the GreenVolt project. Whilst we are waiting for the Twinway project of Hexicon to progress into execution, we now have two projects that the industry is hoping to learn from in the years to come. Behind these projects is a suite of Test & Demonstrate projects that the industry is keen to see if or how the government will facilitate the route to market for in the upcoming Allocation Rounds. Most of the conversations at the conference evolved around the GreenVolt award and the future of the Test & Demonstrate projects. That is of course, unless the topic was the grid connection timeline for a ScotWind project with a name that only the true locals can pronounce correctly.</p>

      <p>For most of the suppliers, it is still a waiting game to be played. When will the developers have matured their supply chain strategy sufficiently to engage with them with a firm contract to be awarded? This might still be some months or years out. However, it might come quicker than the industry thinks. The suppliers will gradually become more and more busy with bottom-fixed wind, so at some point, the nervosity amongst the developers will be overwhelming (aka FOMO amongst teenagers) so the first set of early reservation agreements might come sooner than we think today, but not a day too late for a starving supply chain.</p>



      <p>If you would like to find out more about the services WeBridge offer and how we could help with your floating offshore wind project, please <NavLink to="/contact-us">contact us</NavLink></p>








      <h3 style={{ marginTop: "200px" }}>Subscribe</h3>
      <ContactSubscribe />
    </div>
  )
);
