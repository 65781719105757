import React, { useEffect, useState, useRef } from "react";
import "./AboutUsPage.scss";
import {
  officePhotosArray,
  windmillPhotosArray,
  windmillVideosArray,
  forDevelopersImg,
  forSuppliersImg,
} from "../../assets/assetReference";
import ImgRedBannerTemplate from "../../templates/ImgRedBannerTemplate/ImgRedBannerTemplate";
import RedLineText from "../../components/RedLineText/RedLineText";
import { bannerContent, ourMission, ourValues, ourVision } from "../../data/content/content";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import SlideUpAnimation from "../../components/SlideUpAnimation/SlideUpAnimation";
import LinkTo from "../../components/LinkTo/LinkTo";
import { Helmet } from "react-helmet";

export default function AboutUsPage() {
  return (
    <>
      <Helmet>
        <title>About Us | WeBridge</title>
        <meta
          name="description"
          content={bannerContent.aboutUs}
        />
        <meta
          name="keywords"
          content="WeBridge, wind energy bridge, offshore wind energy, provide, bring, foster, expertise, integrity, collaboration"
        />
      </Helmet>

      <div className={"about-us-page-container"}>
        <ImgRedBannerTemplate
          backgroundImg={{
            src: windmillVideosArray[5],
            alt: "a video of sailing on the sea",
            type: "video",
          }}
          h1={"About Us"}
          redBannerContent={[
            "WeBridge stands as an independent and influential advisor, bridging the gap between European offshore wind developers and global suppliers and service providers.",
            "We offer in-depth business and technical advisory services, underpinned by extensive industry insights, forward-thinking strategies, and bespoke solutions. Leveraging our rich expertise, we guide developers, manufacturers, fabricators, lenders, and insurers through the multifaceted landscape of the offshore wind industry, ensuring their path to exceptional achievements.",
            // "WeBridge is a versatile and influential consulting firm, serving as a bridge between European offshore wind developers and international suppliers.",
            // "We act as a business facilitator that empowers stakeholders through tailored consulting services and strategic partnerships.Our comprehensive services and industry expertise enable us to provide valuable insights, strategic support, and bespoke solutions that empower stakeholders to navigate the complexities of the offshore wind industry and achieve remarkable success.",
          ]}
          // redBannerContent={"WeBridge provides specialised services connecting international offshore wind suppliers with European developers."}
          OtherContent={<OtherContent />}
          num={1}
        />
      </div>
    </>
  );
}

function OtherContent() {
  const videoRef = useRef(null);
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  }, []);
  return (
    <>
      <div className={`other-content`}>
        <div className="our-vision-container container">
          <SlideUpAnimation triggerOnce={false}>
            <div className="content">
              {/* <div className='content' ref={(el) => containerRef.current[0] = el}> */}
              <h2 className="subheading">Our Vision</h2>
              <RedLineText TextContent={<p>{ourVision}</p>} />
            </div>
          </SlideUpAnimation>
        </div>
        <img className="sticky" src={windmillPhotosArray[12]} alt="windmill turbine generator photo" />
        {/* <video autoplay muted loop playsInline ref={videoRef} className="sticky">
          <source src={windmillVideosArray[4]} />
          Your browser does not support the video tag.
        </video> */}
        <div className="our-mission-container container">
          <SlideUpAnimation triggerOnce={false}>
            <div className="content">
              <h2 className="subheading">Our Mission</h2>
              <div className="our-mission-list list">
                {ourMission.map((i, index) => {
                  return (
                    <>
                      {index !== 0 && <div className="divider"></div>}
                      <div className="our-mission-item">
                        <h3>{i.title}</h3>
                        <p>{i.desc}</p>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </SlideUpAnimation>
        </div>
        {/* <img className="sticky" src={windmillVideosArray[4]} alt="windmill photo" /> */}
        <img className="sticky" src={windmillPhotosArray[10]} alt="windmill" />

        {/* <img className="sticky" src={windmillPhotosArray[6]} alt="windmill photo" /> */}
        <div className="our-values-container container">
          <SlideUpAnimation triggerOnce={false}>
            <div className="content">
              <h2 className="subheading">Our Values</h2>
              <div className="our-values-list list">
                {ourValues.map((i) => {
                  return (
                    <div>
                      <img src={i.img} alt="icon" />
                      <h3>{i.title}</h3>
                      <p>{i.desc}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </SlideUpAnimation>

        </div>
      </div>
      <LinkTo
        links={[
          {
            link: "/services/for-developers",
            img: {
              src: forDevelopersImg,
              alt: "offshore windmill windfarm on sea",
            },
            title: "For Developers",
          },
          {
            link: "/services/for-suppliers",
            img: {
              src: forSuppliersImg,
              alt: "offshore windmill construction",
            },
            title: "For Suppliers",
          },
        ]}
      />
    </>
  );
}
