import React from "react";
import "./ForDevelopersPage.scss";
import {
  servicesForDevelopersIcons,
  windmillPhotosArray,
  windmillVideosArray,
} from "../../assets/assetReference";
import ImgRedBannerTemplate from "../../templates/ImgRedBannerTemplate/ImgRedBannerTemplate";
import { bannerContent, servicesForDevelopers } from "../../data/content/content";
import RedLineText from "../../components/RedLineText/RedLineText";
import ServiceBox from "../../components/ServiceBox/ServiceBox";
import SlideUpAnimation from "../../components/SlideUpAnimation/SlideUpAnimation";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function ForDevelopersPage() {
  return (
    <>
      <Helmet>
        <title>For Developers | WeBridge</title>
        <meta
          name="description"
          content={bannerContent.forDevelopers}
        />
        <meta
          name="keywords"
          content={`WeBridge, wind energy bridge, offshore wind energy, offshore wind developers, ${servicesForDevelopers.map(i => " " + i.title)}`}
        />
      </Helmet>
      <div
        className={
          "for-developers-page-container for-suppliers-developers-page-container"
        }
      >
        <ImgRedBannerTemplate
          backgroundImg={{
            src: windmillVideosArray[1],
            alt: "a photo of many offshore windmill turbine generator",
            type: "video",
          }}
          h1="For Developers"
          redBannerContent={
            "WeBridge assist developers in managing their supply chains effectively by connecting them with reliable and high-quality suppliers, suppliers, and service providers. Leveraging our extensive network, we ensure developers have access to the necessary resources and expertise for seamless project implementation."
          }
          OtherContent={<OtherContent />}
          num={1}
        />
      </div>
    </>
  );
}

function OtherContent() {
  return (
    <>
      <div className="other-content">
        <SlideUpAnimation triggerOnce={false}>
          <div className="subheading-container">
            <h2 className="subheading">Our Services</h2>
            <RedLineText
              TextContent={
                <p>
                  By offering specialist services to European developers, WeBridge
                  effectively bridge the demand and supply gaps in the European
                  offshore wind market, facilitating successful collaborations and
                  contributing to the growth and development of the European
                  offshore wind market.
                </p>
              }
            />
          </div>
        </SlideUpAnimation>
        <div className="service-list">
          {servicesForDevelopers.map((i, index) => {
            return (
              <ServiceBox
                img={{ src: servicesForDevelopersIcons[i.icon], alt: `icon for ${i.title}` }}
                item={i}
              />
            );
          })}
        </div>

      </div>
      <NavLink to="/" className='back-to-home'>Back to Home</NavLink>
    </>
  );
}
