import React from 'react';
import './Spinner.scss';

export default function Spinner() {
    return (
        <div class="spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
        </div>
    );
}