import Insight from "../insightClass";
import React from "react";
import { insight16Media } from "../../../assets/assetReference";
import { ContactSubscribe } from "../../../components/Contact/Contact";

export const insight016 = new Insight(
  "28 April 2024",
  {
    pageTitle:
      "WeBridge Sets Sail at the FOWT Conference: A Look Back at Marseille 2024 and the Future of Floating Offshore Wind ",
    metaDescription:
      "WeBridge Insights, WeBridge Sets Sail at the FOWT Conference: A Look Back at Marseille 2024 and the Future of Floating Offshore Wind ",
    keyWords:
      "European Offshore Wind Market development, WeBridge Sets Sail at the FOWT Conference: A Look Back at Marseille 2024 and the Future of Floating Offshore Wind ",
  },
  { src: insight16Media[0], alt: "document" },
  "WeBridge Sets Sail at the FOWT Conference: A Look Back at Marseille 2024 and the Future of Floating Offshore Wind ",
  "",
  `The sun dipped low over the Mediterranean as the final presentations wrapped at the Floating Offshore Wind (FOWT) conference in Marseille this April. For the WeBridge, it was the culmination of three action-packed days filled with inspiring discussions, cutting-edge knowledge exchange, and the undeniable buzz of a burgeoning and maturing industry. `,
  (
    <div>
      <strong>WeBridge Insights</strong>
      <h1>
        WeBridge Sets Sail at the FOWT Conference: A Look Back at Marseille 2024 and the Future of Floating Offshore Wind
      </h1>
      <h2></h2>
      <p style={{ opacity: "0.4", marginTop: "-70px" }}>28 April 2024</p>
      <p>The sun dipped low over the Mediterranean as the final presentations wrapped at the Floating Offshore Wind (FOWT) conference in Marseille this April. For the WeBridge, it was the culmination of three action-packed days filled with inspiring discussions, cutting-edge knowledge exchange, and the undeniable buzz of a burgeoning and maturing industry.</p>
      <p>For the WeBridge team, it was an unmissable event. The 2024 FOWT was significantly bigger than previous years, with more than 1200 attendees, reflecting the growth of the supply chain as we head rapidly towards large-scale commercial floating offshore wind.</p>
      <p>While the event officially kicked off on 24th April, some delegates had a head start with a technical site visit to ETI Offshore and Evolen on the 23rd.</p>
      <p>The official welcome reception was held in the beautiful Grotte Cosques Museum where you could also have the opportunity to visit the replica of the Cosquer Cave. While the setting told the story of a glorious past, the focus of the conference was very much on that of an ambitious future and what is ahead of us in floating wind, particularly in France.</p>
      <img src={insight16Media[0]}
        alt="at a conference: FOWT 2024 in Marseille"
        style={{ marginBottom: "48px" }} />

      <h3>And so, on to the conference</h3>

      <p>We began by being welcomed by local dignitaries who opened the conference on the 24th with an upbeat greeting to all the attendees. What followed were several animated and forward-looking plenary sessions, each bringing their own talking points and highlighting the challenges which lie ahead.</p>

      <p>Of course, FOWT isn't just about the plenaries. The WeBridge team took just as much value (if not more) from the opportunities to gather and mingle with other delegates in the outdoor area. The local weather helped make this a particularly pleasant experience (at least when it wasn't too windy).</p>


      <h3>The topics for debate</h3>

      <p>Away from the stage, what were the hot topics among the delegates? Unsurprisingly, the leasing rounds of the last few months were at the top of many people's minds. What happens here will set the trend. With AO5 about to get announced , what will happen next?</p>


      <p>The energy in the room was palpable, reflecting the significant strides the floating offshore wind industry has taken in recent times. Here's a glimpse into our takeaways from the conference and the exciting future we see for this revolutionary technology.</p>


      <h3>An industry on the rise, supported by collaboration and innovation</h3>

      <p>While significant technical challenges, skills gaps, and supply chain bottlenecks remain, the overall atmosphere at the event was a positive one. What is clear is this: floating offshore wind is no longer a niche technology on the horizon. It's the industry's present and future.</p>

      <p>One of the most inspiring aspects of FOWT was the sense of collaboration that permeated the atmosphere. Here, competitors weren't just rivals but fellow pioneers working together to push the boundaries of what's possible. This collaborative spirit is essential for accelerating technological advancements and ensuring the successful development of large-scale floating wind farms.</p>

      <p>The team at WeBridge had meetings with current clients and met some excellent, high-value new contacts. With both Aaron and Vegard at the event for the full three days, many business cards were exchanged and follow up conversations set up. The level of interest in our offering reflects the intent of the industry to secure an appropriate supply chain to achieve project goals.</p>


      <h3>Final thoughts on FOWT 2024</h3>

      <p>As we reflect on our experience at FOWT 2024, one thing is certain: the future of floating offshore wind is bright. The confluence of positive licensing announcements, technological advancements, and a growing focus on sustainability creates the ingredients for  continued industry growth.</p>

      <p>At WeBridge, we're excited to be part of this industry. We're committed to connecting ambitious developers with innovative international suppliers who, together, can realise their floating offshore wind projects. We believe that by working together, the industry can unlock the full potential of this game-changing technology and create a cleaner, more sustainable future for generations to come.</p>







      <h3 style={{ marginTop: "200px" }}>Subscribe</h3>
      <ContactSubscribe />
    </div>
  )
);
