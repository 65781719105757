import React from "react";
import "./ForInvestorsPage.scss";
import {
    backgroundArtArray,
    servicesForInvestorsIcons,
    windmillPhotosArray,
    windmillVideosArray,
} from "../../assets/assetReference";
import ImgRedBannerTemplate from "../../templates/ImgRedBannerTemplate/ImgRedBannerTemplate";
import { bannerContent, servicesForDevelopers, servicesForInvestor } from "../../data/content/content";
import RedLineText from "../../components/RedLineText/RedLineText";
import ServiceBox from "../../components/ServiceBox/ServiceBox";
import SlideUpAnimation from "../../components/SlideUpAnimation/SlideUpAnimation";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function ForInvestorsPage() {
    return (
        <>
            <Helmet>
                <title>For Investors | WeBridge</title>
                <meta
                    name="description"
                    content={bannerContent.forInvestor}
                />
                <meta
                    name="keywords"
                    content={`WeBridge, wind energy bridge, offshore wind energy, offshore wind developers, ${servicesForInvestor.map(i => " " + i.title)}`}
                />
            </Helmet>
            <div
                className={
                    "for-developers-page-container for-suppliers-developers-page-container"
                }
            >

                <ImgRedBannerTemplate
                    backgroundImg={{
                        src: backgroundArtArray.investors,
                        alt: "a photo of two people analysing using graphs",
                        type: "img",
                    }}
                    h1="For Investors"
                    redBannerContent={
                        bannerContent.forInvestor
                    }
                    OtherContent={<OtherContent />}
                    num={1}
                />
            </div>
        </>
    );
}

function OtherContent() {
    return (
        <>
            <div className="other-content">
                <SlideUpAnimation triggerOnce={false}>
                    <div className="subheading-container">
                        <h2 className="subheading">Our Services</h2>
                        <RedLineText
                            TextContent={
                                <p>
                                    Our industry expertise and tailored solutions empower investors to navigate the complexities of the industry, contribute to well-informed financial practices, and support projects that align with their financial and risk management goals.
                                </p>
                            }
                        />
                    </div>
                </SlideUpAnimation>
                <div className="service-list">
                    {servicesForInvestor.map((i, index) => {
                        return (
                            <ServiceBox
                                img={{ src: servicesForInvestorsIcons[i.icon], alt: `icon for ${i.title}` }}
                                item={i}
                            />
                        );
                    })}
                </div>

            </div>
            <NavLink to="/" className='back-to-home'>Back to Home</NavLink>
        </>
    );
}
