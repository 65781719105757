import Insight from "../insightClass";
import React from "react";
import { insight18Media } from "../../../assets/assetReference";
import { ContactSubscribe } from "../../../components/Contact/Contact";

export const insight018 = new Insight(
  "4 July 2024",
  {
    pageTitle:
      "6 reasons why UK offshore wind requires a 'glocal' approach",
    metaDescription:
      "WeBridge Insights, 6 reasons why UK offshore wind requires a 'glocal' approach",
    keyWords:
      "European Offshore Wind Market development, 6 reasons why UK offshore wind requires a 'glocal' approach",
  },
  { src: insight18Media[0], alt: "document" },
  "6 reasons why UK offshore wind requires a 'glocal' approach",
  "The UK's offshore wind industry is a success story. But international collaboration is essential to keep development targets on track.",
  `By 2030 the UK's offshore wind sites are forecast to provide more than half of the nation's power requirements. The industry supports over 30,000 jobs  and is home to the world's five largest offshore wind farms.  Each gigawatt of new offshore wind adds £2-3bn of value to the UK economy.`,
  (
    <div>
      <strong>WeBridge Insights</strong>
      <h1>
        6 reasons why UK offshore wind requires a 'glocal' approach
      </h1>
      <h2>The UK's offshore wind industry is a success story. But international collaboration is essential to keep development targets on track.</h2>
      <p style={{ opacity: "0.4", marginTop: "-70px" }}>4 July 2024</p>

      <img src={insight18Media[0]}
        alt="Offshore Wind farm"
        style={{ marginBottom: "48px" }} />

      <p>By 2030 the UK's offshore wind sites are forecast to provide more than half of the nation's power requirements. The industry supports over 30,000 jobs<sup><a style={{ textDecoration: "none" }} href="#fn1" id="ref1">1</a></sup> and is home to the world's five largest offshore wind farms.<sup><a style={{ textDecoration: "none" }} href="#fn2" id="ref2">2</a></sup> Each gigawatt of new offshore wind adds £2-3bn of value to the UK economy.<sup><a style={{ textDecoration: "none" }} href="#fn3" id="ref3">3</a></sup></p>
      <p>Yet while there's plenty to feel positive about, there's also a commercial imperative to take a global approach to the development of offshore wind infrastructure. For so many reasons the UK is unable to meet its own offshore wind targets without significant collaboration with international suppliers.</p>
      <p>That's why a 'glocal' mindset is needed when developing the required supply chain: promoting competitive, local contributions when possible and looking globally to plug the gaps. This article introduces six driving forces behind this glocal imperative.</p>

      <h3>1. Global demand means global bottlenecks</h3>
      <p>The UK has the second biggest pipeline of offshore wind projects globally. But many governments have their sights trained keenly on offshore wind development. Pick any country with a coastline and it's a safe bet that they will be investing. And the numbers tell the story.</p>
      <p>At the end of 2023 there was over 300GW of offshore wind in the global pipeline scheduled to begin construction by 2030. By 2035 there's expected to be more than 750GW of global offshore wind capacity. That's almost ten times the global capacity at the end of 2023.<sup><a style={{ textDecoration: "none" }} href="#fn4" id="ref4">4</a></sup></p>
      <p>Such ambitions require tripling the current average annual capacity additions. That creates additional strains on an already creaking supply chain - whether that's materials (such as steel), components (such as cables) or assembly infrastructure (such as ringer cranes - of which there are fewer than ten in the world).</p>
      <p>Perhaps it's no surprise that <a href="https://splash247.com/uk-to-miss-2030-offshore-wind-goal-by-18-years-study-claims/" target="_blank">a recent study</a> found that the UK is due to miss it's 2030 offshore wind targets by 18 years.</p>

      <h3>2. Lack of investment in UK steel</h3>
      <p>Steel is a key material in the development of offshore wind components. But despite the former glory of the domestic industry, steel isn't in abundant supply in the UK. Between now and 2035 19 million tonnes of steel are needed to satisfy the UK's offshore wind pipeline.<sup><a style={{ textDecoration: "none" }} href="#fn5" id="ref5">5</a></sup> That equates to around 20% of the UK's total annual steel production going directly to offshore wind.<sup><a style={{ textDecoration: "none" }} href="#fn6" id="ref6">6</a></sup></p>
      <p>That may not sound like an insurmountable challenge. But consider that UK steel is also in demand for onshore energy infrastructure, rail track development, electric vehicle production and construction. And in many ways it's immaterial: the UK doesn't currently have any companies that are producing the grade and thickness of steel required for the structures used in offshore wind.</p>
      <p>There's also the cost of domestic steel production to contend with. UK steel is costly due to comparatively high electricity prices. In 2022 UK steel producers paid 61% higher electricity prices compared to competitors in Germany.<sup><a style={{ textDecoration: "none" }} href="#fn7" id="ref7">7</a></sup> Building offshore wind infrastructure domestically would cost too much compared to the strike price that developers have locked into.</p>
      <p>Significant investment in the UK steel industry is required as well as energy subsidies for the UK to be able to produce its own offshore wind infrastructure. British Steel's new electric arc furnace at Teesworks is a step in the right direction. Also at Teesworks, SeAH Wind are developing <a href="https://www.teesworks.co.uk/news/british-steel-to-supply-huge-seah-wind-plant-at-teesworks" target="_blank">the world's largest monopile factory</a>. This is progress. But more investment is needed - and fast.</p>
      <p>It's hard to build up a domestic steel production and construction industry that hasn't been competitive for 40 years.</p>

      <h3>3. Land availability and construction time</h3>
      <p>The UK isn't blessed with land availability. Especially in coastal areas where manufacturing sites for offshore wind need to be located. Any government acquisition of land through a compulsory purchase order would most likely be legally contested. Development of brownfield land would be subject to planning approval. These things take time.</p>
      <p>Assuming a site were approved, it takes around 5-7 years to finance, design and build a steel plant or a construction yard of significant size. And it's not as if any site will do. The increasing scale of offshore wind components means that manufacturing sites must have vast storage space - not to mention easy access to deep water ports. An example: the monopiles that are forecasted for development at the aforementioned SeAH Wind site will be 120 metres in length, 15.5 metres in diameter and weigh in at 3,000 tonnes.</p>
      <p>Offshore wind infrastructure is colossal. Colossal structures require lots of space.</p>

      <h3>4. There's no steel construction availability in Europe</h3>
      <p>If there's no sufficient steel construction capability in the UK for offshore wind, we are obligated to look further afield. Europe is the logical place to start. Yet no matter where you look, supply challenges abound. European construction yards have gone 'all in' on bottom-fixed offshore wind infrastructure - and are struggling to meet the existing pipeline. The need for the production of monopiles and jackets will be significant for the next decade in Europe, with demand expected to outstrip production capacity in 2026.<sup><a style={{ textDecoration: "none" }} href="#fn8" id="ref8">8</a></sup></p>
      <p>As the UK offshore wind industry matures - and ventures into deeper water - the demand is not for fixed-bottom, but for floating wind infrastructure. However there's no commercial incentive for European construction yards to pivot into related fabrication when they are set up for bottom-fixed and have a long pipeline of orders to fulfil.</p>
      <p>As the saying goes, why change a winning horse?</p>
      <p>Even if all construction yards optimised their offering for both floating and bottom-fixed wind projects, it still wouldn't be enough to meet the volume of offshore wind projects required to reach the ambitious green targets set out by the UK and the rest of Europe.</p>


      <h3>5. Construction capacity is out there - in the east</h3>
      <p>The lack of steel construction yard availability isn't a problem that's confined to Europe. Steelworks in the Middle East are focused on advanced innovations for the oil and gas industries. Japan might have some availability but production is too expensive to be reconciled with the strike prices of UK offshore wind developments. South Korea also has construction capacity but tends to favour higher margins in other construction sectors.</p>
      <p>That brings us to China. It's priced competitively. It has proven experience and expertise. And it has capacity thanks to demand from the oil and gas industries tapering. Indeed it's becoming <a href="https://www.rechargenews.com/wind/maybe-with-mingyang-analysis-suggests-french-floating-tender-may-founder-without-chinese-turbines/2-1-1652762" target="_blank">increasingly recognised</a> among European developers that the use of Chinese construction yards is a viable strategy for keeping offshore wind developments on track and within budget.</p>

      <h3>6. Shipping costs necessitate local assembly </h3>
      <p>Even with the shipping of vast steel components and larger sub-structures halfway across the world, construction in the Far East is cheaper than in Europe. Therefore it's financially preferable to prefabricate the necessary components in the Far East and undertake final assembly domestically. This also helps to boost compliance with local content requirements.</p>
      <p>And that brings us full circle. A 'glocal' approach is what's required to keep the UK's offshore wind developments on track. Given the global development pipeline, the realities of the global steel industry and the crude geographical constraints of land availability around the UK coast, it makes sense commercially, operationally and logically to source prefabricated steel components from China before assembling them locally.</p>



      <br />
      <br />
      <hr></hr>
      <br />
      <sup id="fn1">1. [Offshore Wind Industry Council (June 2023), Offshore Wind Skills Intelligence report]</sup><br />
      <sup id="fn2">2. [2024 Offshore Wind Industrial Growth Plan: Expanding the Horizon of the UK's Offshore Wind Supply Chain]</sup><br />
      <sup id="fn3">3. [ORE Catapult (2017), The Economic Value of Offshore Wind]</sup><br />
      <sup id="fn4">4. [2024 Offshore Wind Industrial Growth Plan: Expanding the Horizon of the UK's Offshore Wind Supply Chain]</sup><br />
      <sup id="fn5">5. [National Composites Centre]</sup><br />
      <sup id="fn6">6. [24 Offshore Wind Industrial Growth Plan: Expanding the Horizon of the UK's Offshore Wind Supply Chain.]</sup><br />
      <sup id="fn7">7. [MakeUK (November 2021), A barrier to decarbonisation: Industrial electricity prices faced by UK steelmakers]</sup><br />
      <sup id="fn8">8. [2024 Offshore Wind Industrial Growth Plan: Expanding the Horizon of the UK's Offshore Wind Supply Chain]</sup><br />
      <br />
      <br />
      <br />


      <h3>How can WeBridge help?</h3>
      <p>As global competition for offshore wind supply chains intensifies, countries with a long-term strategic approach will be the most successful in keeping their scheduled projects on track. With expert understanding of both European and Chinese offshore wind markets, WeBridge can act as an interface between European developers and Chinese suppliers. <a href="https://www.windenergybridge.com/" className="font-bold" target="_blank">Here's how.</a></p>



      <h3>You may also be interested in:</h3>
      <p>&gt;&gt; <a href="https://www.windenergybridge.com/insights/keeping-offshore-wind-developments-on-track-in-a-time-of-expensive-capital" target="_blank">Keep offshore wind developments on track in a time of expensive capital</a></p>
      <p>&gt;&gt; <a href="https://www.windenergybridge.com/insights/importing-steel-from-china:-duties-and-tariffs-payable" target="_blank">Importing steel from China: duties and tariffs payable</a></p>
      <p>&gt;&gt; <a href="https://www.windenergybridge.com/insights/offshore-wind-developments:-derisking-the-import-of-chinese-steel-" target="_blank">Offshore wind developments: derisking the import of Chinese steel</a></p>





      <h3 style={{ marginTop: "200px" }}>Subscribe</h3>
      <ContactSubscribe />
    </div>
  )
);
