import Insight from "../insightClass";
import React from "react";
import { insight10Media } from "../../../assets/assetReference";
import { ContactSubscribe } from "../../../components/Contact/Contact";



export const insight010 = new Insight(
    "22 October 2023",
    {
        pageTitle: "WeBridge travels around China",
        metaDescription: "WeBridge Insights, WeBridge travels around China",
        keyWords: "European Offshore Wind Market development, WeBridge travels around China",
    },
    { src: insight10Media[0], alt: "WeBridge logo" },
    "WeBridge travels around China",
    "WeBridge partner Aaron Du spent much of September and October this year travelling around China to meet new connections and fabricators",
    `WeBridge partner Aaron Du has spent most of September and October travelling around China, attending events and visiting fabricator yards. Here is his round up of the trip.`,
    <div >
        <strong>WeBridge Insights</strong>
        <h1>WeBridge travels around China</h1>
        <h2>WeBridge partner Aaron Du spent much of September and October this year travelling around China to meet new connections and fabricators</h2>
        <p style={{ opacity: "0.4", marginTop: "-70px" }}>22 October 2023</p>
        <p>WeBridge partner Aaron Du has spent most of September and October travelling around China, attending events and visiting fabricator yards. Here is his round up of the trip.</p>
        <p>The 2023 <span style={{ color: "#b92d22" }}>China Wind Power</span> Exhibition was held in Beijing over 17-19th October. This expo is the largest wind power event in Asia and brings together key players in the wind energy industry from around the world.</p>
        <p>The scale of the expo was impressive, with over 1,000 exhibitors showcasing the latest wind power technologies and products. I was able to check out the newest wind turbines, components, equipment, and services from leading companies. It was fascinating to see how far wind energy related technology has advanced in China in recent years.  </p>
        <img src={insight10Media[0]} alt="" />
        <br />
        <br />
        <br />
        <p>Beyond the exhibition floor, the conference program provided an in-depth look at wind power trends and policies in China. I attended several sessions on topics like offshore wind projects, wind hybrid power systems, digitalisation of wind farms, and more. The presentations and discussions underscored China's commitment to continuing its rapid growth in wind power capacity.</p>
        <img src={insight10Media[1]} alt="" />
        <br />
        <br />
        <br />
        <p>As I navigate the complexities of integrating the offshore wind supply chains between Europe and China, the knowledge and connections I've garnered here are invaluable. The potential for synergy is immense, and the conversations I've had have opened up new avenues for collaboration. I eagerly anticipate participating in future events in China.</p>
        <p>I also had the opportunity to tour two impressive offshore wind fabrication facilities in Penglai, Shandong during my trip - the <span style={{ color: "#b92d22" }}>Dajin</span> yard and the <span style={{ color: "#b92d22" }}>PJOE</span> yard.</p>
        <p>The Dajin yard focuses on the fabrication of monopile foundations for offshore wind turbines. Their massive facility contains advanced equipment for rolling and shaping thick steel plates into XXL monopile sections. I was amazed by the sheer size and scale of the monopiles they produce. The automation and quality control processes they have implemented are world-class. As of the end of October 2023, they have already delivered five shipments of the <span style={{ color: "#b92d22" }}>Ocean Winds Moray West</span> monopiles, with each shipment containing eight monopiles. Only one shipment remains to be delivered. </p>
        <img src={insight10Media[2]} alt="" />
        <br />
        <br />
        <br />
        <p>The PJOE yard specialises in the fabrication of jacket foundations for offshore wind. Their yard utilises precision welding techniques and industry unique assembling towers to manufacture complex jackets with the utmost accuracy and consistency. The jackets they fabricate ship out to offshore wind farm sites across the Asia-Pacific region and beyond. Most recently, they have produced 50 jackets to the <span style={{ color: "#b92d22" }}>SSE Seagreen</span> project in the UK.</p>
        <img src={insight10Media[3]} alt="" />
        <br />
        <br />
        <br />
        <p>Both Dajin and PJOE are clearly leaders in their respective areas of offshore wind fabrication. Their facilities, equipment, and expertise are remarkable to see firsthand. Chinese yards like these will play a key role in supporting the growth of offshore wind energy not only in China but globally. I'm grateful I had the chance to visit these impressive fabrication yards and learn more about their capabilities.</p>
        <p>If you would like an introduction to either of these suppliers or would like to find out more about how WeBridge can help streamline collaboration between European offshore wind developers and Chinese manufacturers please get in contact <a href="mailto:adu@windenergybridge.com">adu@windenergybridge.com</a></p>



        <h3 style={{ marginTop: "200px" }}>Subscribe</h3>
        <ContactSubscribe />
    </div >
);