import React, { useEffect, useState, useContext } from "react";
import "./Contact.scss";
import { sendEmail } from "../../api/email";
import Spinner from "../Spinner/Spinner";





export function ContactSubscribe() {

    const [name, setName] = useState('');
    const [company, setCompany] = useState("");
    const [position, setPosition] = useState("");
    const [email, setEmail] = useState('');

    const [disableButton, setDisableButton] = useState(false);

    const [response, setResponse] = useState('');



    const submitForm = async (e) => {
        e.preventDefault();
        if (!(name.trim() && email.trim() && company.trim() && position.trim())) {
            setResponse("Please fill all * fields.");
            return;
        }
        if (!email.trim().replace(" ", "").includes("@")) {
            setResponse("Please input a valid email address.");
            return;
        }
        setDisableButton(true);
        const response = await sendEmail({ type: "subscribe", name, email, company, position });
        if (response === 200) {
            setResponse("Email sent successfully");
            setName("");
            setCompany("");
            setPosition("");
            setEmail("");
        } else {
            setResponse("Email failed");
        }
        setDisableButton(false);
    };

    return (
        <div className="about-contact-us" id="contact-us">
            <form className="about-contact-us-form" onSubmit={submitForm} onClick={() => { setResponse(""); }}>
                <FormItem
                    label="Full Name*"
                    placeholder="Your name"
                    value={name}
                    set={setName}
                />
                <div className="flex uneven">
                    <FormItem
                        label="Company*"
                        placeholder="Your company"
                        value={company}
                        set={setCompany}
                    />
                    <FormItem
                        label="Position*"
                        placeholder="Your position"
                        value={position}
                        set={setPosition}
                    />
                </div>
                <FormItem
                    label="Email*"
                    placeholder="your@email.com"
                    value={email}
                    set={setEmail}
                    type="email"
                />
                <div className="form-item button">
                    <button language="en" type="submit" disabled={disableButton} style={{ opacity: disableButton ? "0.7" : "1" }}>
                        {disableButton ? <Spinner /> : "Subscribe"}
                    </button>
                </div>
                <p>{response}</p>
                <p style={{ fontSize: "0.8rem" }}>如果您在中国大陆，表格无法提交，请用邮件或者微信联系我们。</p>
            </form>
        </div>
    );
}
export function ContactEnquiry() {

    const [name, setName] = useState('');
    const [company, setCompany] = useState("");
    const [position, setPosition] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [content, setContent] = useState('');
    const [disableButton, setDisableButton] = useState(false);

    const [response, setResponse] = useState('');



    const submitForm = async (e) => {
        e.preventDefault();
        if (!(name.trim() && email.trim() && subject.trim() && content.trim() && company.trim() && position.trim())) {
            setResponse("Please fill all * fields.");
            return;
        }
        if (!email.trim().replace(" ", "").includes("@")) {
            setResponse("Please input a valid email address.");
            return;
        }
        setDisableButton(true);
        const response = await sendEmail({ type: "enquiry", name, email, subject, content, company, phone, position });
        if (response === 200) {
            setResponse("Email sent successfully");
            setEmail("");
            setName("");
            setSubject("");
            setContent("");
            setCompany("");
            setPhone("");
            setPosition("");
        } else {
            setResponse("Email failed");
        }
        setDisableButton(false);
    };

    return (
        <div className="about-contact-us" id="contact-us">
            <form className="about-contact-us-form" onSubmit={submitForm} onClick={() => { setResponse(""); }}>
                <FormItem
                    label="Full Name*"
                    placeholder="Your name"
                    value={name}
                    set={setName}
                />
                <div className="flex uneven">
                    <FormItem
                        label="Company*"
                        placeholder="Your company"
                        value={company}
                        set={setCompany}
                    />
                    <FormItem
                        label="Position*"
                        placeholder="Your position"
                        value={position}
                        set={setPosition}
                    />
                </div>
                <div className="flex">
                    <FormItem
                        label="Email*"
                        placeholder="your@email.com"
                        value={email}
                        set={setEmail}
                        type="email"
                    />
                    <FormItem
                        label="Phone"
                        placeholder=""
                        value={phone}
                        set={setPhone}
                        type="tel"
                    />
                </div>
                <FormItem
                    label="Subject*"
                    placeholder="Developers"
                    value={subject}
                    set={setSubject}
                />
                <div className="form-item">
                    <label>
                        <span language="en">Message*</span>
                        <textarea className="txt" placeholder="Your message here" value={content} onChange={(e) => { setContent(e.target.value); }}></textarea>
                    </label>
                </div>
                <div className="form-item button">
                    <button language="en" type="submit" disabled={disableButton} style={{ opacity: disableButton ? "0.7" : "1" }}>
                        {disableButton ? <Spinner /> : "SUBMIT"}
                    </button>
                </div>
                <p>{response}</p>
                <p style={{ fontSize: "0.8rem" }}>如果您在中国大陆，表格无法提交，请用邮件或者微信联系我们。</p>
            </form>
        </div>
    );
}

function FormItem(props) {
    return (
        <div className="form-item">
            <label>
                <span language="en">{props.label}</span>
                <input className="txt"
                    type={props.type || 'text'}
                    placeholder={props.placeholder}
                    value={props.value}
                    onChange={(e) => { props.set(e.target.value); }} />
            </label>
        </div>
    );
}
