import Insight from "../insightClass";
import React from "react";
import { insight12Media } from "../../../assets/assetReference";
import { ContactSubscribe } from "../../../components/Contact/Contact";



export const insight012 = new Insight(
    "15 December 2023",
    {
        pageTitle: "COP28 Reflections: Leading the Charge in Energy Transition",
        metaDescription: "WeBridge Insights, COP28 Reflections: Leading the Charge in Energy Transition",
        keyWords: "European Offshore Wind Market development, COP28 Reflections: Leading the Charge in Energy Transition",
    },
    { src: insight12Media[0], alt: "cop28" },
    "COP28 Reflections: Leading the Charge in Energy Transition",
    "",
    `Just back from an inspiring journey at COP28 in Dubai. The summit concluded with a historic pledge to phase down fossil fuel use and a commitment from over 100 countries to triple renewable energy capacity by 2030.`,
    <div >
        <strong>WeBridge Insights</strong>
        <h1>COP28 Reflections: Leading the Charge in Energy Transition</h1>
        <h2></h2>
        <p style={{ opacity: "0.4", marginTop: "-70px" }}>15 December 2023</p>


        <p>Just back from an inspiring journey at COP28 in Dubai. The summit concluded with a historic pledge to phase down fossil fuel use and a commitment from over 100 countries to triple renewable energy capacity by 2030. As an offshore wind consultant, I was particularly inspired by the strengthened commitments for clean energy adoption and the acceleration of the clean energy transition.</p>

        <img src={insight12Media[1]} alt="cop28" style={{ marginBottom: "48px" }} />
        <p><b>European Leadership:</b> The European leadership on display was encouraging - ambitious targets, huge investments in wind, solar, and other renewables, and a commitment to phasing out fossil fuels. Innovative strategies and commitment to sustainable practices set a benchmark for global efforts.</p>
        <img src={insight12Media[2]} alt="cop28" style={{ marginBottom: "48px" }} />
        <p><b>Middle East Efforts:</b> The Middle East's commitment to sustainable energy solutions was a significant highlight. It is determined to be part of the solution through massive solar projects and green hydrogen plans.</p>
        <img src={insight12Media[3]} alt="cop28" style={{ marginBottom: "48px" }} />
        <p><b>China's Contributions:</b> China's involvement and contributions added a crucial dimension to the dialogue. China will deliver almost 70 percent of all new, offshore wind projects globally, over 60 percent of onshore wind, and 50 percent of solar photovoltaic (PV) projects.</p>
        <img src={insight12Media[4]} alt="cop28" style={{ marginBottom: "48px" }} />
        <p><b>Global Collaborations:</b> Global collaboration was a major theme, with developed and developing countries acknowledging their common but differentiated responsibilities. Discussions underlined the necessity of international cooperation in overcoming the challenges faced by the renewable energy sector, including supply chain complexities. Enhancing supply chain sustainability through innovative solutions, strategic partnerships, education, and awareness.</p>
        <img src={insight12Media[5]} alt="cop28" style={{ marginBottom: "48px" }} />
        <p><b>Personal Highlight:</b> One of the most heartwarming experiences was bringing my 4.5-year-old daughter to the green zone. She loved learning about climate change through interactive games and meeting adorable robots who explained the impact of rising temperatures. As a parent, it gave me hope to see her generation engaged on this critical issue.</p>
        <img src={insight12Media[6]} alt="cop28" style={{ marginBottom: "48px" }} />
        <p>The transition is underway, the technology exists, and political will is building. If we work together, a decarbonised economy is within reach and our children will inherit a liveable planet!</p>
        <img src={insight12Media[7]} alt="cop28" style={{ marginBottom: "48px" }} />


        <h3 style={{ marginTop: "200px" }}>Subscribe</h3>
        <ContactSubscribe />
    </div >
);