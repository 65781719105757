import React from 'react';
import './RedLineText.scss';

export default function RedLineText(props) {
    return (
        <div className={"red-line-text"}>
            <div className="red-line"></div>
            {/* <p>{props.text}</p> */}
            {props.TextContent}
        </div>
    );
}