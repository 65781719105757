import Insight from "../insightClass";
import React from "react";
import { insight2Media, windmillPhotosArray } from "../../../assets/assetReference";
import { ContactEnquiry, ContactSubscribe } from "../../../components/Contact/Contact";



export const insight002 = new Insight(
    "12 July 2023",
    {
        pageTitle: "China Enters the Era of 16+MW Offshore Wind Turbines ",
        metaDescription: "WeBridge Insights, China Enters the Era of 16+MW Offshore Wind Turbines",
        keyWords: "16MW",
    },
    { src: windmillPhotosArray[13], alt: "a zoomed in windmill turbine generator" },
    "China Enters the Era of 16+MW Offshore Wind Turbines",
    "Goldwind, MingYang, and CSSC Haizhuang Lead the Way",
    "China's entrance into the realm of 16+ megawatts (MW) wind turbines denotes a pivotal advancement in the global renewable energy landscape. Three leading Original Equipment Manufacturers (OEMs) in China – Goldwind, MingYang, and CSSC Haizhuang – have heralded this technological innovation, affirming China's influential role in the wind energy domain.",
    <div >
        <strong>WeBridge Insights</strong>
        <h1>China Enters the Era of 16+MW Offshore Wind Turbines</h1>
        <h2>Goldwind, MingYang, and CSSC Haizhuang Lead the Way </h2>
        <p style={{ opacity: "0.4", marginTop: "-70px" }}>12 July 2023</p>
        <h3>Introduction</h3>
        <p>China's entrance into the realm of 16+ megawatts (MW) wind turbines denotes a pivotal advancement in the global renewable energy landscape. Three leading Original Equipment Manufacturers (OEMs) in China – <a href="https://www.goldwind.com/en/" target="_blank">Goldwind</a>, <a href="http://www.myse.com.cn/en/" target="_blank">MingYang</a>, and <a href="http://cssc-hz.com/?en/" target="_blank">CSSC Haizhuang</a> – have heralded this technological innovation, affirming China's influential role in the wind energy domain. This article presents an analytical overview of this evolution, shedding light on technological, market, and policy drivers.</p>
        <img src={insight2Media[0]} alt="windmill turbine generator photo" />
        <p className="fig-desc">Photo source: <a href="https://www.ctg.com.cn/en/" target="_blank">Three Gorges Group</a></p>
        <h3>National Context and Policy</h3>
        <p>In the past decade, China's wind turbine technology was notably lagging behind the leading Western firms such as Vestas, Siemens Gamesa, and General Electric (GE). However, the recent impressive feat represents a successful "overtaking on a curve" by Chinese turbine manufacturers. This term, common in China, signifies how a latecomer can surpass its rivals in a race by capitalizing on technological advancements and market trends. China's manufacturers have effectively used their unique position in the global supply chain and their relentless pursuit of innovation to leapfrog their competition and produce the world's largest offshore wind turbines.</p>
        <p>For years, China has been at the forefront of the renewable energy revolution, heavily investing in wind power as a clean and sustainable alternative to conventional energy sources. The country's ambitious goals and commitment to combating climate change have driven significant advancements in wind turbine technology.</p>
        <h3>Key Players and Technological Innovation</h3>
        <img className="small" src={insight2Media[1]} alt="goldwind logo" />
        <h4>Goldwind</h4>
        <p>Goldwind, established in 1998, is one of China's largest wind turbine manufacturers and one of the world's leading wind power companies. Involved in every aspect of wind energy, from turbine manufacturing to wind farm development and operation, Goldwind has earned a reputation for innovation and quality, and boasts a robust portfolio of efficient and reliable wind turbines.</p>
        <p>Goldwind's entry into the 16 MW wind turbine market represents a substantial leap forward. In November 2022, Goldwind and the Three Gorges Group (CTG) jointly announced the 16MW offshore wind turbine GWH252-16MW. In June 2023, the turbine was successfully rolled out at the CTG Industrial Park in Fujian, becoming the wind turbine unit with the largest rotor diameter of 252m, highest single-machine capacity, and lightest weight per megawatt in the world. In June 2023, the first GWH252-16MW was installed at CTG’s 400 MW <a href="https://www.offshorewind.biz/2023/06/26/breaking-ctg-installing-worlds-largest-wind-turbine-offshore-china/" target="_blank">Zhangpu Liuao Phase 2 offshore wind farm</a> in Fujian, becoming the world's first 16 MW wind turbine installed offshore.</p>
        <img className="small" src={insight2Media[2]} alt="mingyang logo" />
        <h4>MingYang Smart Energy</h4>
        <p>MingYang Smart Energy is one of the leading wind turbine manufacturers in China. Founded in 2006, the company specializes in manufacturing high-quality wind turbines, offering products of different capacities to meet diverse needs. In August 2023, Mingyang launched the MySE16.0-242, an offshore Hybrid Drive wind turbine with a 16MW capacity and a 242m diameter rotor. In January 2023, Mingyang introduced the world's largest floating wind turbine, MySE16.X-260. On the same day, Mingyang also officially launched the 18MW MySE18.X-28X, the largest offshore wind turbine unit in the world, equipped with blades exceeding 140 meters in length. These developments showcase MingYang's ability to innovate and push the boundaries of wind energy technology.</p>
        <img className="small" src={insight2Media[3]} alt="cssc logo" />
        <h4>CSSC Haizhuang</h4>
        <p>CSSC Haizhuang, a subsidiary of China State Shipbuilding Corporation (CSSC), is a renowned player in the wind energy market, recognized for its sophisticated wind power equipment and solutions. The company's achievements span offshore, onshore, and intertidal wind power projects. In January 2023, the H260-18MW offshore wind turbine developed independently by CSSC Haizhuang was successfully launched in Shandong. Additionally, CSSC Haizhuang announced the completion of the development of the H260-18MW series. The company will continue to develop models with a rotor diameter of over 300 meters and output power exceeding 25MW, in line with its established technological roadmap.</p>
        <h3>Market Dynamics and Ecosystem</h3>
        <p>China's remarkable progress in the wind energy sector can be attributed to a combination of factors. Government support for renewable energy projects and favourable policies have created a conducive environment for technological advancements and investment. Moreover, the country's vast domestic market for wind energy has provided a testing ground for innovation, allowing manufacturers to refine their products before expanding globally.</p>
        <p>According to statistics, nearly 60% of wind power equipment in the global market is produced in China. By the end of 2022, the cumulative installed capacity of offshore wind power globally reached 57.6 gigawatts, with China accounting for 30.51 gigawatts, representing a 53% market share. China's cumulative wind power export capacity reached 11.93 million kilowatts, covering 49 countries and regions, and the export of wind power products from China is rapidly increasing.</p>
        <h3>Conclusion</h3>
        <p>China's recent initiation of 16+MW wind turbines through Goldwind, Mingyang, and CSSC Haizhuang constitutes a landmark achievement in renewable energy. The implications extend beyond national borders, contributing to the global endeavour to transition towards a more sustainable energy future. As the world's largest carbon emitter, China's technological contributions are not only significant for domestic growth but vital in the collective effort to combat climate change. By nurturing innovation and driving the commercialization of advanced wind turbine technology, China delineates a new chapter in the global energy narrative.</p>
        <h3 style={{ marginTop: "200px" }}>Subscribe</h3>
        <ContactSubscribe />
    </div >
);