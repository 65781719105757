import Insight from "../insightClass";
import React from "react";
import { insight6Media } from "../../../assets/assetReference";
import { ContactSubscribe } from "../../../components/Contact/Contact";



export const insight006 = new Insight(
    "16 August 2023",
    {
        pageTitle: "Keeping offshore wind developments on track in a time of expensive capital",
        metaDescription: "WeBridge Insights, Keeping offshore wind developments on track in a time of expensive capital ",
        keyWords: "European Offshore Wind Market development, capital",
    },
    { src: insight6Media[0], alt: "offshore wind farm" },
    "Keeping offshore wind developments on track in a time of expensive capital",
    "Facing high interest, high inflation and supply chain challenges, a more global approach to procurement can ease pressures on Europe's offshore wind developers",
    `Since Vattenfall pulled out of the Norfolk Boreas project in July, several high profile articles have questioned the commercial viability of offshore wind development in Europe. With ambitious plans for the role of offshore wind in green energy transition targets, the pressure is on to find new ways to keep development costs in check. `,
    <div >
        <strong>WeBridge Insights</strong>
        <h1>Keeping offshore wind developments on track in a time of expensive capital</h1>
        <h2>Facing high interest, high inflation and supply chain challenges, a more global approach to procurement can ease pressures on Europe's offshore wind developers</h2>
        <p style={{ opacity: "0.4", marginTop: "-70px" }}>16 August 2023</p>
        <p>Since Vattenfall <a href="https://www.offshorewind.biz/2023/07/20/breaking-vattenfall-stops-developing-major-wind-farm-offshore-uk-will-review-entire-4-2-gw-zone/" target="_blank">pulled out of the Norfolk Boreas project</a> in July, several <a href="https://www.ft.com/content/8b8aaa67-0c9c-47a6-949d-09bd79a5241d" target="_blank">high profile articles</a> have questioned the commercial viability of offshore wind development in Europe. With <a href="https://windenergybridge.com/insights/why-europe-needs-new-entrants-into-the-supply-chain-for-offshore-wind" target="_blank">ambitious plans</a> for the role of offshore wind in green energy transition targets, the pressure is on to find new ways to keep development costs in check.</p>
        <img src={insight6Media[0]} alt="offshore wind farm" />
        <h3>Key financial pressures on offshore wind developments</h3>
        <p>High interest and inflation have enhanced pressure on a supply chain that was already stressed. The cost of materials is increasing. Structural supply shortages compound the problem. Many suppliers are working on wafer-thin margins. And the cost of human resource has gone up, while labour supply has fallen.</p>
        <p>Such financial pressures and uncertainties increase the cost of borrowing capital. That's a big problem. The vast majority of developers borrow significant sums from banks to finance construction. Cornwall Insight's <a href="https://www.cornwall-insight.com/press/soaring-cost-of-capital-threatens-to-derail-expansion-of-renewable-energy-projects/" target="_blank">report</a> into capital costs revealed rising inflation and interest rates, supply chain problems and labour shortages have increased the weighted average cost of capital (WACC) for renewable projects by about 4% since early 2021.</p>
        <p>It's a vicious cycle.</p>
        <h3>Contracts for Difference: both help and hindrance?</h3>
        <p>In the UK, offshore wind developers are guaranteed a set price for each MWh of power they produce under the Contracts for Difference (CfD) scheme. This price, known as the strike price, is locked in - typically for a period of 15 years - regardless of what happens to the wholesale electricity price. In short: developers have the security that if the market rate falls, the UK government will top up their earnings to meet the pre-agreed strike price.</p>
        <p>The CfD scheme is designed to bring more certainty to developers and make it easier to access development capital on preferential terms. However, the scheme works both ways. When the wholesale electricity price surges - as has happened since the Ukraine invasion - developers must pay back any earnings above the strike price to the government. In essence this leaves developers out of pocket, earning less than if they had sold at the market rate instead of locking in to a CfD.</p>
        <p>Despite this, <a href="https://pemedianetwork.com/carbon-economist/articles/renewables/2022/renewable-cfds-still-in-demand-despite-power-price-surge/" target="_blank">demand for CfDs is still high</a>. But there is growing concern in the industry about a key facet of CfDs that dictates how much a developer can earn. Under the CfD scheme, developers bid for new projects in an auction. However with each auction the government imposes a limit on the maximum strike price a developer can receive. This is known as the administrative strike price (ASP).</p>
        <p>Despite rising costs, ASPs have decreased significantly since the start of the CfD scheme. There is now concern that unless ASPs increase soon, developers may be deterred from bidding for new offshore wind projects for fear they will not be able to balance the books. It's one more reason there is growing urgency to find new ways to reduce the high upfront costs of offshore wind developments.</p>
        <h3>Looking to the East for steel</h3>
        <p>China is a powerhouse of steel manufacture - accounting for around half of the world's total production. Thanks to vast manufacturing capabilities, a deeply integrated supply chain and economies of scale, Chinese steel can be significantly more affordable than steel procured through European markets.</p>
        <p>Yet the appeal of sourcing steel from China goes beyond price. In contrast to European supply chains, Chinese suppliers and fabricators have good availability. Delays cost money, especially during a time of high interest and high inflation. The comparative readiness of Chinese steel fabricators to produce a variety of offshore wind components enhances the speed at which an offshore development can be completed, connected to the grid and used to generate income.</p>
        <h3>What about import tariffs and shipping costs?</h3>
        <p>Of course, the enhanced economies of purchasing Chinese steel must be balanced against import duties and shipping costs. These are variable and conditional on the type of assets you are importing and in what volume. However, as a general rule, it's typical to achieve significant savings on steel components such as monopiles, jackets and towers - even after the various import duties have been applied.</p>
        <h3>Is Chinese steel good quality?</h3>
        <p>Historically there have been reservations about the quality of Chinese steel. But China's production capabilities have enhanced dramatically. The growth in the industry is such that many of China's fabricators are now accustomed to working with clients across Europe and North America, producing components to exacting quality standards. Indeed Chinese steel is regularly used in offshore oil and gas production facilities for huge operators such as BP, Shell and Equinor.</p>
        <p>And that is to say nothing of China's own booming offshore wind industry. China is the world leader in offshore wind energy production, with 30.2 GW of facilities fully operational and a further 6.2 GW under construction as of Q2 2023.* The success of China's world-leading investment in domestic offshore wind energy production is ample testimony of their fabrication capabilities.</p>
        <p style={{ fontSize: "0.7em" }}>* Source: 4C Offshore: Global Market Overview - Q2 2023.</p>
        <h3>Importing steel from China: let us help</h3>
        <p>The availability and affordability of steel from China can be instrumental in mitigating the spiralling costs of offshore wind developments and helping Europe to meet its ambitious production targets. But importing from an unfamiliar market presents challenges: identifying a suitable fabricator, communicating the project specifications and making sure assets are manufactured to the right quality.</p>
        <p>With deep expertise in both European and Chinese offshore wind markets, we can act as an intermediary on your project - managing the unknowns and making sure your development capitalises on the economic benefits of importing steel from China without compromising on quality. <a href="https://windenergybridge.com/services/for-developers" target="_blank">Here's a little more about what we do</a>.</p>
        <h3 style={{ marginTop: "200px" }}>Subscribe</h3>
        <ContactSubscribe />
    </div >
);