import Insight from "../insightClass";
import React from "react";
import { insight7Media } from "../../../assets/assetReference";
import { ContactSubscribe } from "../../../components/Contact/Contact";



export const insight007 = new Insight(
    "30 August 2023",
    {
        pageTitle: "Importing steel from China: duties and tariffs payable",
        metaDescription: "WeBridge Insights, Importing steel from China: duties and tariffs payable",
        keyWords: "European Offshore Wind Market development, Importing steel from China: duties and tariffs payable",
    },
    { src: insight7Media[0], alt: "offshore wind farm" },
    "Importing steel from China: duties and tariffs payable",
    "Calculating duties when importing steel to the UK or EU from China can be complicated. Here's a brief explainer.",
    `European nations have set ambitious targets for enhancing their offshore wind energy production. As of Q2 2023 the UK is aiming to ramp up to 50 GW by 2030. Germany is aiming for 50 GW by 2035.`,
    <div >
        <strong>WeBridge Insights</strong>
        <h1>Importing steel from China: duties and tariffs payable</h1>
        <h2>Calculating duties when importing steel to the UK or EU from China can be complicated. Here's a brief explainer.</h2>
        <p style={{ opacity: "0.4", marginTop: "-70px" }}>30 August 2023</p>
        {/* <p style={{ opacity: "0.4", marginTop: "-70px" }}>16 August 2023</p> */}



        <img src={insight7Media[0]} alt="two man, calculating, with graphs and a calculator" />

        <h3>The commercial case for importing steel from China</h3>
        <p>European nations have set ambitious targets for enhancing their offshore wind energy production. As of Q2 2023 the UK is aiming to ramp up to 50 GW by 2030. Germany is aiming for 50 GW by 2035. And France is aiming for an additional 18 GW operational by 2035. Aside from other major markets like Belgium, Denmark and the Netherlands, several other European nations are embarking on their first forays into wind energy production - including Estonia, Finland, Italy and Portugal.*</p>
        <p>The scale and ambition of these plans is crucial for meeting green energy transition targets. But these intentions are not without significant challenges. There is a shortfall in the amount of fabricators and high-quality steel that will be needed for the construction of wind turbine towers, foundations, transition pieces and other components. These pressures are exacerbated by the high inflationary environment and the rising cost of accessing capital.</p>
        <p>China is well positioned to address this deficit. But European project stakeholders must be aware of the applicable import tariffs and duties.</p>
        <p>Here's a quick summary.  </p>
        <p>&gt;&gt; <a href="https://windenergybridge.com/insights/how-steel-from-china-have-a-pivotal-role-to-play-in-european-offshore-wind" target="_blank">How steel from China can have a pivotal role in European offshore wind </a> </p>
        <p>&gt;&gt; <a href="https://windenergybridge.com/insights/keeping-offshore-wind-developments-on-track-in-a-time-of-expensive-capital" target="_blank">Keeping offshore wind developments on track in a time of expensive capital</a></p>
        <p style={{ fontSize: "0.7em" }}>* Source: 4C Offshore: Global Market Update - Q2 2023.</p>

        <h3>Value-added tax (VAT)</h3>
        <p>VAT is the most basic and commonly understood type of import tax. A key point to remember is that VAT is calculated not just on the value of the goods imported, but the value of the goods as well as their customs value. In essence that means VAT is calculated on the value of goods as well as the total cost of getting the goods to the country of import: shipping costs, insurance and any applicable customs duties. (More on those below.)</p>
        <p>In the UK, VAT on imported goods is typically 20%. As for the European Union, standard VAT rates vary from country to country - ranging from 16% in Luxembourg to 27% in Hungary. You can get a breakdown of each country's typical VAT rate <a href="https://www.globalvatcompliance.com/globalvatnews/vat-rates-in-europe-2021/" target="_blank">here</a>.</p>

        <h3>Third country duty</h3>
        <p>Both the UK and EU countries apply a third country duty to imported goods. This varies based on the type of product and the country of origin. For example, importing fresh bananas to the UK from Columbia incurs a duty of £62.00 per 1,000kg. But for fresh bananas imported from the Ivory Coast, the duty rate is slashed to 0%.</p>
        <p>Many steel products also carry a 0% duty when imported from China - in both the UK and Europe.</p>

        <h4>Additional tariffs</h4>
        <p>There are a range of additional - constantly fluctuating - tariffs that are used to help control the flow of goods into the importing country. The function of these tariffs is to protect domestic markets from cheap imports that could harm the prosperity of local industries. A key point to understand is that these additional tariffs may be applied against a specific country, a specific region or even a specific business within the exporting country.</p>

        <h4>Anti-dumping duty</h4>
        <p>An exporter is deemed to be 'dumping' goods when they export to the UK or EU at a significantly lower price than the value of the product - usually in order for the exporter to gain exposure to new markets.</p>

        <h4>Countervailing duty</h4>
        <p>Similar to anti-dumping duty, countervailing duty is a measure used to negate the impact of any government subsidies or grants that a manufacturer has benefited from in the country of origin. It's possible to have both anti-dumping duty and countervailing duty on the same product.</p>

        <h4>Safeguard duty</h4>
        <p>Safeguard duty may be imposed on goods that are being imported in increased quantities. It's normally triggered once a pre-determined volume of a certain product has been imported.</p>

        <h4>Tariff-rate quotas</h4>
        <p>Similar to safeguarding, some products are covered by a tariff-rate quota (TRQ). If there's a TRQ for the product you are importing, you can apply to import a limited amount at a zero or reduced rate of customs duty. Once the TRQ is exceeded, a higher tariff applies.</p>

        <h3>What tariffs are applicable to offshore wind developments?</h3>
        <p>Perhaps the most significant tariff to be aware of is the EU's anti-dumping tariff on wind towers. This applies to towers designed to support the nacelle and rotor blades in turbines with a generating capacity in excess of 1 MW and a minimum height of 50 metres. The duty is set at 19.2%. However this tariff is reduced to between 7.2% and 14.4% if the steel is sourced from one of a small selection of named Chinese exporters.</p>
        <p>This anti-dumping tariff doesn't apply when importing to the UK, where the third country duty is also set at 0%. The only import fee applicable when importing a wind tower to the UK is the 20% VAT. The UK does however implement anti-dumping tariffs on a selection of other types of Chinese steel, including cold-rolled steel, hot-rolled steel bars and steel ropes and cables.</p>
        <p>Across the EU and UK there's no tariff on monopile or jacket foundations. Yet even where tariffs are applicable, importing steel from China is incredibly cost effective. Thanks to the nation's vast production capabilities, integrated supply chain and economies of scale, developers can achieve major savings on offshore wind energy assets.</p>

        <h3>Know your tariff codes</h3>
        <p>The key to calculating applicable import duties is understanding which tariff codes your goods fall under. This coding system indexes a vast number of products into hyper-specific categories and sub-categories using unique 10-digit codes. Both the UK and the EU have their own tariff code systems, with slight differences - though the first six numbers of each system's coding standard are universal. For instance, all steel products across both systems begin with the 7308 nomenclature. And all steel tower and lattice masts begin with the 730820 nomenclature.</p>
        <p>You can browse tariff duties for the UK <a href="https://www.gov.uk/trade-tariff" target="_blank">here</a>, and for the EU <a href="https://ec.europa.eu/taxation_customs/dds2/taric/taric_consultation.jsp" target="_blank">here</a>.</p>

        <h3>Importers have a legal obligation to get it right</h3>
        <p>The tariff code systems are complex and misclassifications are common. Simply Duty <a href="https://www.simplyduty.com/get-hs-codes/" target="_blank">estimate</a> that as many as 30% of imports are imported with the incorrect code. Yet the admin surrounding the import of goods isn't something you want to get wrong. It's the importer's legal obligation to accurately declare the right goods and pay the appropriate fees. Failure to do so can incur fines and penalties as well as resulting in customs holding on to or rejecting imported goods. All necessary duty is payable at the time the goods are imported, however established importers can often arrange to defer payment for an average of 30 days.</p>

        <h3>Streamline the import of Chinese steel with WeBridge</h3>
        <p>The legalities and bureaucracy surrounding goods import is complex and in a constant state of flux. At WeBridge we exist to streamline collaboration between European offshore wind developers and Chinese manufacturers to make sure European developments are completed safely, smoothly and profitably. <a href="https://windenergybridge.com/services/for-developers" target="_blank">Here are some of the ways we help</a>.</p>


        <h3 style={{ marginTop: "200px" }}>Subscribe</h3>
        <ContactSubscribe />
    </div >
);
