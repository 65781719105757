import Insight from "../insightClass";
import React from "react";
import { constructionPhotosArray, insight3Media } from "../../../assets/assetReference";
import { ContactEnquiry, ContactSubscribe } from "../../../components/Contact/Contact";



export const insight003 = new Insight(
    "19 July 2023",
    {
        pageTitle: "How Steel from China have a Pivotal Role to Play in European Offshore Wind",
        metaDescription: "WeBridge Insights, How Steel from China have a Pivotal Role to Play in European Offshore Wind. The only way to hit meet GW targets",
        keyWords: "European Offshore Wind",
    },
    { src: constructionPhotosArray[5], alt: "a photo of contruction site of windmill turbine generator" },
    "How Steel from China have a Pivotal Role to Play in European Offshore Wind",
    "The Only Way to Meet GW Targets",
    "The rapid expansion of offshore wind energy has become a cornerstone of the Europe’s renewable energy ambitions, aiming to achieve carbon neutrality and reduce dependency on fossil fuels. In 2021 alone, across the EU, 14.6 GW of offshore capacity was installed. In this pursuit, Chinese steel has started emerging as a crucial player, providing the essential foundation for the development and growth of offshore wind farms across Europe.  ",
    <div >
        <strong>WeBridge Insights</strong>
        <h1>How Steel from China have a Pivotal Role to Play in European Offshore Wind</h1>
        <h2>The Only Way to Meet GW Targets</h2>
        <p style={{ opacity: "0.4", marginTop: "-70px" }}>19 July 2023</p>
        <h3>Rapid expansion in Europe</h3>
        <p>The rapid expansion of offshore wind energy has become a cornerstone of the Europe’s renewable energy ambitions, aiming to achieve carbon neutrality and reduce dependency on fossil fuels. In 2021 alone, across the EU, <a href="https://energy.ec.europa.eu/topics/renewable-energy/offshore-renewable-energy_en" target="_blank">14.6 GW of offshore capacity</a> was installed. In this pursuit, Chinese steel has started emerging as a crucial player, providing the essential foundation for the development and growth of offshore wind farms across Europe.</p>
        <p>This article delves into the reasons behind the prominent role of Chinese steel in the European offshore wind industry and the potential benefits and challenges it presents.</p>
        <h3>The surge in Offshore Wind Projects</h3>
        <p>The demand for renewable energy sources in Europe has led to a significant increase in offshore wind projects over the past decade. The scale of these projects requires massive amounts of high-quality steel for the construction of wind turbine towers, foundations, transition pieces, and other steel components.</p>
        <p>Europe has made significant strides in developing a mature offshore wind infrastructure supply chain. European steel manufacturers have been supplying the industry with high-quality products, ensuring adherence to stringent safety and environmental standards. However, as the demand for offshore wind capacity increases, the local supply chain faces challenges in meeting the soaring requirements. Conversely, China boasts an equally mature offshore wind supply chain, bolstered by vast steel production capabilities and cost-competitiveness. To date, China has installed <a href="https://www.offshorewind.biz/2023/06/30/china-now-has-31-gw-of-offshore-wind-installed-country-on-track-to-hit-wind-and-solar-targets-five-years-early-report-says/" target="_blank">over 30GW offshore wind power</a>, representing nearly 50% of the global market share.</p>
        <p>To achieve ambitious GW targets, Europe must contemplate the most practical and viable options for the massive quantities of steel required. While Europe's local supply chain remains essential, the scale of growth necessitates augmenting it with additional sources. In this context, China's supply chain emerges as the most logical solution to address the pressing demand.</p>
        <img src={insight3Media[0]} alt="picture of offshore wind farm(Image: China General Nuclear Power Corp)" />
        <p className="fig-desc">(Image: China General Nuclear Power Corp)</p>
        <h3>Quality standards and compliance</h3>
        <p>China has emerged as a leading global producer of steel for infrastructures. European offshore wind developers have been conscious of maintaining high-quality standards, safety, and durability in fabricating offshore wind structures. Chinese steel manufacturers and fabricators have invested in quality improvements and have become well-versed in meeting international standards and gaining European certifications, making them attractive partners for European offshore wind projects. Many European offshore energy projects use Chinese steel in the last decade, which has exhibited excellent mechanical properties, durability, and corrosion resistance, crucial characteristics for withstanding the harsh offshore environments.</p>
        <h3>Cost-Competitiveness and Economies of Scale</h3>
        <p>Chinese steel manufacturers have been able to offer cost-competitive solutions due to their extensive experience, economies of scale, and robust production capabilities. With the ability to produce large quantities of steel, China has strategically positioned itself as a vital supplier for various global infrastructure projects, including a significant shift towards offshore wind farms.  The availability of affordable steel from China can be instrumental in driving down overall construction costs for European offshore wind projects, particularly for those located in deep-water areas, where more complex structures like jackets and floating foundations are required.</p>
        <img src={insight3Media[1]} alt="picture of offshore wind farm(Image: China General Nuclear Power Corp)" />
        <p className="fig-desc">(Image: China General Nuclear Power Corp)</p>
        <h3>Supply Chain Resilience</h3>
        <p>The energy crisis in the wake of Russia-Ukraine war is pressuring the Europe’s ability to secure reliable and affordable steel products from local suppliers. Overreliance on a single source could lead to potential vulnerabilities and disruptions in the event of trade disputes or geopolitical tensions. Therefore, a diversified supply chain is crucial for the success of the offshore wind industry. By leveraging steel from China, European offshore wind projects can enhance supply chain resilience and mitigate risks associated with material shortages or price fluctuations. Developing a resilient supply chain strategy that includes multiple suppliers from different regions is vital for the long-term sustainability of the European offshore wind sector.</p>
        <h3>Strategic Partnerships and Collaboration</h3>
        <p>The strategic partnership between Europe and China in the offshore wind industry goes well beyond the boundaries of the supply chain. Collaborations between European engineering and fabrication firms and their Chinese counterparts have proven to be mutually advantageous, fostering knowledge exchange and enhancing project efficiency. A prime example of such collaboration can be seen in the fabrication of large-scale floating foundations, where certain structural components are manufactured in China and then assembled in Europe. This cooperative approach not only optimizes the use of Chinese steel in European projects but also helps meet local content requirements, nurturing a harmonious working relationship between the two regions.</p>
        <h3>Environmental and Sustainability Considerations</h3>
        <p>With sustainability being a paramount concern, European companies have scrutinized the environmental impact of sourcing steel from China. While China has faced criticism for its carbon-intensive steel production in the past, the country has been making significant strides in reducing its environmental impact. The Chinese government has implemented stringent regulations and policies aimed at curbing emissions and promoting sustainable practices within the steel industry. This progress aligns with Europe's commitment to sustainability, ensuring that the steel used in offshore wind projects contributes to a low-carbon future.</p>
        <p>Long transportation distances raise questions about the carbon footprint associated with shipping steel across continents. However, both European and Chinese stakeholders are increasingly cognizant of the importance of reducing emissions along the supply chain, and efforts are taking proactive steps to address these concerns. One of the mitigation measures is Carbon Offsetting: To compensate for unavoidable emissions during long transportation, stakeholders can consider investing in carbon offsetting initiatives. These initiatives may involve supporting renewable energy projects, reforestation efforts, or other activities that absorb or reduce CO2 emissions.</p>
        <h3>Conclusion</h3>
        <p> Chinese steel will undoubtedly play a major role in propelling the growth of the European offshore wind industry. Its cost-competitiveness, quality improvements, and strategic collaborations will facilitate the realisation of ambitious renewable energy targets. However, to ensure a sustainable and robust future, developers and stakeholders must carefully manage supply chain risks and continue fostering cooperation among nations to harness the full potential of offshore wind energy in Europe.</p>
        <p className="last contact"><b><i>"Please don't hesitate to contact the WeBridge team (Email: <a href="mailto:contact@windenergybridge.com">contact@windenergybridge.com</a>) if you are interested in learning more about insights into the Chinese offshore wind supply chain."</i></b></p>
        <h3 style={{ marginTop: "200px" }}>Subscribe</h3>
        <ContactSubscribe />

    </div >
);