import React, { useEffect } from 'react';
import './ImgWhiteTemplate.scss';
import { backgroundArtArray } from '../../assets/assetReference';

export default function ImgWhiteTemplate(props) {
    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, []);
    return (
        <div className={"img-white-template-container img-background-template"}>


            <div className='background'>
                <img className='img top' src={props.backgroundImg.src} alt={`${props.backgroundImg.alt}`} />
                <div className='title'>
                    <h1>{props.h1}</h1>
                </div>
            </div>
            <div className="main-content">
                <img className='img back' src={backgroundArtArray.windmillArt} alt="2d offshore windmill art for background" />
                {props.OtherContent}
            </div>

        </div>
    );
}

