import Insight from "../insightClass";
import React from "react";
import { insight5Media } from "../../../assets/assetReference";
import { ContactSubscribe } from "../../../components/Contact/Contact";



export const insight005 = new Insight(
    "02 August 2023",
    {
        pageTitle: "The world's First Integrated Offshore Wind and Aquaculture System Completed in Guangdong, China ",
        metaDescription: "WeBridge Insights, The world's First Integrated Offshore Wind and Aquaculture System Completed in Guangdong, China ",
        keyWords: "European Offshore Wind Market",
    },
    { src: insight5Media[0], alt: "offshore windmill construction at sea" },
    "The world's First Integrated Offshore Wind and Aquaculture System Completed in Guangdong, China ",
    "Mingyang's Blended Offshore Solution",
    `Mingyang successfully completed the world's first installation of an offshore wind turbine featuring a jacket foundation and an integrated intelligent aquaculture net-cage system, christened "Ming Yu No.1" in Yangjiang, Guangdong, on August 12th, 2023.`,
    <div >
        <strong>WeBridge Insights</strong>
        <h1>The world's First Integrated Offshore Wind and Aquaculture System Completed in Guangdong, China </h1>
        <h2>Mingyang's Blended Offshore Solution</h2>
        <p style={{ opacity: "0.4", marginTop: "-70px" }}>02 August 2023</p>
        <p style={{ marginTop: "100px" }}><a href="http://www.myse.com.cn/en/" target="_blank">Mingyang</a> successfully completed the world's first installation of an offshore wind turbine featuring a jacket foundation and an integrated intelligent aquaculture net-cage system, christened "Ming Yu No.1" in Yangjiang, Guangdong, on August 12th, 2023. This cutting-edge fusion of wind energy with aquaculture represents a significant stride toward a new era of sustainable and low-carbon fishery.</p>
        <img src={insight5Media[0]} alt="offshore windmill construction at sea" />
        <p className="fig-desc">Photo Source: Mingyang</p>
        <p>Located 69 kilometers off the coast in water depths of 48 meters, Mingyang's Yangjiang Qingzhou Four offshore wind farm project encompasses a total capacity of 500 MW. It will include more than 40 large offshore wind turbines with capacities of 11 MW, 12 MW, and 16.6 MW.</p>
        <p>The wind turbine has the potential to generate up to 45 million kWh annually, powering 23,000 homes and mitigating CO2 emissions by 38,000 tons. Beyond the benefits of wind energy, each turbine foundation unit can accommodate over 150,000 fish, leading to an annual yield of 75 tons. This translates to an additional production value of 18 million RMB (approximately 2 million GBP). The integration of these two vital industries substantially enhances the project's investment returns and contributes to the concentrated utilization of marine resources.</p>
        <img src={insight5Media[1]} alt="offshore windmill construction at sea" />
        <p className="fig-desc">Photo Source: Mingyang</p>
        <p>"Ming Yu No.1" serves as a key innovation within Mingyang's Yangjiang Qingzhou Four offshore wind farm. It stands as the first global endeavour to achieve profound integration between offshore wind and marine farming across various domains, such as structural design, electricity distribution, information transmission, and maintenance. With its wind turbine jacket foundation, "Ming Yu No.1" seamlessly incorporates high-performance netting and intelligent aquaculture systems, without the need for additional offshore installation.</p>
        <p>Engineered to withstand a once-in-50-years typhoon, this unique design addresses the recurring typhoon challenges in the South China Sea. It guarantees the robustness and reliability of both wind energy and deep-sea farming operations.</p>
        <p>Furthermore, the integration of intelligent aquaculture technology facilitates smart feeding, real-time monitoring, automated net cleaning, and fish collection. This promotes the cultivation of high-quality fish species such as snapper and grouper.</p>
        <img src={insight5Media[2]} alt="offshore windmill construction at sea" />
        <p className="fig-desc">Photo Source: Mingyang</p>
        <p>As the world grapples with the pressing concerns of population growth and sustainability, including energy, food, and environmental challenges, Mingyang is at the forefront of merging leading technology with rich marine ecological resources. This comprehensive approach fosters the synergistic development of offshore wind, seawater hydrogen production, and marine farming.</p>
        <p>Reflecting on this significant achievement, Zhang Chuanwei, Chairman of Mingyang Group, emphasised that the confluence of offshore wind energy, hydrogen production, and marine farming symbolizes a future trend. Mingyang is committed to the relentless pursuit of constructing and investing in integrated offshore energy industries, propelling the high-quality fusion of marine technology, equipment, energy, and economy.</p>
        <img src={insight5Media[3]} alt="offshore windmill construction at sea" />
        <p className="fig-desc">Photo Source: Mingyang</p>
        <h3 style={{ marginTop: "200px" }}>Subscribe</h3>
        <ContactSubscribe />
    </div >
);