import Insight from "../insightClass";
import React from "react";
import { insight17Media } from "../../../assets/assetReference";
import { ContactSubscribe } from "../../../components/Contact/Contact";

export const insight017 = new Insight(
  "25 June 2024",
  {
    pageTitle:
      "Global Offshore Wind, Manchester 18-19th June 2024",
    metaDescription:
      "WeBridge Insights, Global Offshore Wind, Manchester 18-19th June 2024",
    keyWords:
      "European Offshore Wind Market development, Global Offshore Wind, Manchester 18-19th June 2024",
  },
  { src: insight17Media[0], alt: "document" },
  "Global Offshore Wind, Manchester 18-19th June 2024",
  "",
  `Global Offshore Wind is one of the premier events for offshore wind in the UK, but due to the UK's leading position in offshore wind it also attracts participants from around the world. With this year's event taking place in Manchester rather than in London, fewer international visitors might have attended the show, but it nonetheless turned out to be a great display of developers, contractors and other stakeholders in the industry.`,
  (
    <div>
      <strong>WeBridge Insights</strong>
      <h1>
        Global Offshore Wind, Manchester 18-19th June 2024
      </h1>
      <h2></h2>
      <p style={{ opacity: "0.4", marginTop: "-70px" }}>25 June 2024</p>

      <p>Global Offshore Wind is one of the premier events for offshore wind in the UK, but due to the UK's leading position in offshore wind it also attracts participants from around the world. With this year's event taking place in Manchester rather than in London, fewer international visitors might have attended the show, but it nonetheless turned out to be a great display of developers, contractors and other stakeholders in the industry.</p>
      <img src={insight17Media[0]}
        alt="Global Offshore Wind event show floor"
        style={{ marginBottom: "48px" }} />
      <p>Whilst the UK politicians shared their views on how best to shape the offshore wind industry going forward, the individuals in the exhibition hall were busy firming up their networks. Although few contracts were signed during the conference, future opportunities were discussed in abundance.</p>
      <p>Like most conferences, Global Offshore Wind is a balancing act between planning and letting chance play a part in who you will meet when you walk around the conference. This time, we had tried to plan back-to-back meetings at a conference room in a hotel next to the conference hall. The planning worked well. It gave WeBridge and our partners a better opportunity to discuss prospects with potential clients and partners without the lively background noise of the conference. In the end, some meetings were moved around, and we were able to spend a couple of hours walking around at the conference. As expected, it meant going from one encounter to another, exchanging ideas and business cards (physical and electronic). We were also able to meet individuals we hadn't been able to fit into our initial schedule, so it worked out very well in the end.</p>
      <p>Between the two days of the conference, WeBridge had the pleasure of sponsoring the UK-China Offshore Wind Dinner organised by the China-Britain Business Council. The attendees included European developers as well as Chinese and UK-based contractors, all of whom saw the benefits of an intercontinental collaboration.</p>
      <p>Looking back at the conference and the outcome for us and our partners, we can positively say it was a success. An analogue encounter with people is still a delightful way of staying connected with the rest of the offshore wind industry. In short, we are looking forward to next year's conference already. See you there!     </p>

      {/* <img src={insight17Media[1]}
        alt="Global Offshore Wind event poster"
        style={{ marginBottom: "48px" }} /> */}












      <h3 style={{ marginTop: "200px" }}>Subscribe</h3>
      <ContactSubscribe />
    </div>
  )
);
