import React, { useContext } from 'react';
import './ForSuppliersPage.scss';
import { constructionPhotosArray, servicesForSuppliersIcons, weBridgeLogo, windmillPhotosArray, windmillVideosArray } from '../../assets/assetReference';
import ImgRedBannerTemplate from '../../templates/ImgRedBannerTemplate/ImgRedBannerTemplate';
import RedLineText from '../../components/RedLineText/RedLineText';
import ServiceBox from '../../components/ServiceBox/ServiceBox';
import { bannerContent, companyInformation, servicesForSuppliers } from '../../data/content/content';
import SlideUpAnimation from '../../components/SlideUpAnimation/SlideUpAnimation';
import { GlobalContext } from '../../context/globalContext';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default function ForSuppliersPage() {
    return (
        <>
            <Helmet>
                <title>For Suppliers | WeBridge</title>
                <meta
                    name="description"
                    content={bannerContent.forSuppliers}
                />
                <meta
                    name="keywords"
                    content={`WeBridge, wind energy bridge, offshore wind energy, offshore wind suppliers, ${servicesForSuppliers.map(i => " " + i.title)}`}
                />
            </Helmet>
            <div className={"for-suppliers-page-container for-suppliers-developers-page-container"}>
                <ImgRedBannerTemplate
                    backgroundImg={{ src: windmillVideosArray[2], alt: "offshore windmill photo", type: "video" }}
                    h1="For Suppliers"
                    redBannerContent={"WeBridge's services for suppliers aim to empower them to navigate the European offshore wind market successfully. By providing comprehensive tailored business solutions, we help suppliers establish a strong presence, secure projects, and maximise their success in this promising industry."}
                    OtherContent={<OtherContent />}
                    num={1}
                    contain={true}
                />
            </div>
        </>
    );
}

function OtherContent() {
    const { isSmallScreen } = useContext(GlobalContext);

    return (
        <>
            <div className='other-content'>
                <SlideUpAnimation triggerOnce={false}>
                    <div className="subheading-container">
                        <h2 className="subheading">Our Services</h2>
                        <RedLineText TextContent={<p>WeBridge offer a comprehensive range of services to international suppliers looking to enter and succeed in the European offshore wind market. Our services are designed to support suppliers at every stage of their business journey and enable them to maximise their opportunities in this rapidly growing industry.</p>} />
                    </div>
                </SlideUpAnimation>
                <div className="service-list">
                    {servicesForSuppliers.map((i, index) => {
                        return (
                            <ServiceBox
                                img={{ src: servicesForSuppliersIcons[i.icon], alt: `icon for ${i.title}` }}
                                item={i}
                            />
                        );
                    })}
                    {/* {servicesForSuppliers.map((i, index) => {
                        if (index === 4) {
                            return (
                                <>
                                    {!isSmallScreen &&
                                        <div className="middle-logo">
                                            <img src={weBridgeLogo.white} alt="WeBridge logo" />
                                        </div>
                                    }
                                    <ServiceBox img={{ src: constructionPhotosArray[index], alt: "windmill photo" }} item={i} />
                                </>
                            );
                        } else {
                            return (
                                <ServiceBox img={{ src: constructionPhotosArray[index], alt: "windmill photo" }} item={i} />
                            );
                        }
                    })} */}

                </div>
            </div>
            <NavLink to="/" className='back-to-home'>Back to Home</NavLink>
        </>
    );
}