import React from "react";
import "./LinkTo.scss";
import { NavLink } from "react-router-dom";

// props: {name:string, link:string, img:Object}
export default function LinkTo(props) {
  return (
    <div className={"link-to-container"}>
      {props.links.map((i) => {
        return (
          <div className="link-to-item">
            <NavLink to={i.link} alt={`link to ${i.title} page`}>
              <img src={i.img.src} alt={i.img.alt} />
              <p>{i.title}</p>
            </NavLink>
          </div>
        );
      })}
    </div>
  );
}
