import Insight from "../insightClass";
import React from "react";
import { insight11Media } from "../../../assets/assetReference";
import { ContactSubscribe } from "../../../components/Contact/Contact";



export const insight011 = new Insight(
    "16 November 2023",
    {
        pageTitle: "UK Government Boosts Offshore Wind Sector with Much Higher Auction Prices",
        metaDescription: "WeBridge Insights, UK Government Boosts Offshore Wind Sector with Much Higher Auction Prices!",
        keyWords: "European Offshore Wind Market development, UK Government Boosts Offshore Wind Sector with Much Higher Auction Prices!",
    },
    { src: insight11Media[0], alt: "offshore wind" },
    "UK Government Boosts Offshore Wind Sector with Much Higher Auction Prices!",
    "",
    `On 16th November 2023, the UK government has significantly raised the maximum prices for offshore wind projects in its upcoming Contracts for Difference (CfD) auction. This move is aimed at reinforcing the UK's status as a global leader in clean energy.`,
    <div >
        <strong>WeBridge Insights</strong>
        <h1>UK Government Boosts Offshore Wind Sector with Much Higher Auction Prices!</h1>
        <h2></h2>
        <p style={{ opacity: "0.4", marginTop: "-70px" }}>16 November 2023</p>

        <img src={insight11Media[1]} alt="offshore wind" />
        <br />
        <br />
        <br />
        <p>On 16th November 2023, the UK government has significantly raised the maximum prices for offshore wind projects in its upcoming Contracts for Difference (CfD) auction. This move is aimed at reinforcing the UK's status as a global leader in clean energy. The changes are expected to attract continued investment in the UK and further cement its position in the renewable energy sector.</p>
        <p>Key Highlights are summarised as below:</p>
        <ul>
            <li>Increased Maximum Prices: The maximum strike price for offshore wind projects has been increased by 66%, from £44/MWh to £73/MWh. For floating offshore wind projects, the increase is 52%, from £116/MWh to £176/MWh.</li>
            <li>Continued Investment and Growth: The CfD scheme, which guarantees a fixed price for electricity generated by renewable projects, has been instrumental in increasing the UK's renewable electricity generation from 6% in 2010 to 48% in 2023.</li>
            <li>Separate Funding for Offshore Wind: Recognizing the abundance of ready offshore wind projects, a separate funding pot will be allocated to ensure healthy competition and help the UK achieve its goal of 50GW of offshore wind by 2030, including up to 5GW of floating offshore wind.</li>
            <li>Focus on Sustainable and Social Impact: Projects will be assessed not just on cost but also on their sustainability and social impact, like supply chain carbon emissions and job creation.</li>
            <li>Annual Auctions and Industry Rewards: The introduction of annual auctions and the consideration of Sustainable Industry Rewards in the 2025 auction process aim to incentivize projects that reduce carbon emissions and enhance social benefits.</li>
        </ul>
        <br />
        <br />
        <br />
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
            <img src={insight11Media[2]} alt="WeBridge Quote" className="phone-full-width" />
        </div>
        <h3>WeBridge Comments:</h3>
        <p>The WeBridge expert team, specialising in the international offshore wind supply chains, views these developments as a significant step forward for the sector. The increased maximum prices reflect the government's commitment to renewable energy and its understanding of the current economic challenges faced by the industry. This move is expected to bolster investor confidence and drive further growth in the offshore wind sector.</p>
        <p>The focus on sustainability and social impact aligns with global trends in renewable energy, where environmental and community benefits are increasingly becoming key factors in project development. The separate funding for offshore wind projects is a strategic decision that recognizes the potential of this sector to contribute significantly to the UK's energy mix and its net zero targets.</p>
        <p>Overall, the UK's approach serves as a model for other countries looking to expand their renewable energy capabilities. The combination of financial incentives, sustainability considerations, and strategic funding allocations positions the UK as a leader in the global transition to clean energy.</p>




        <h3 style={{ marginTop: "200px" }}>Subscribe</h3>
        <ContactSubscribe />
    </div >
);