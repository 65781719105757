import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import './UnsubscribePage.scss';
import { sendEmail } from '../../api/email';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../components/Spinner/Spinner';

export default function UnsubscribePage() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState('');
    const [reason, setReason] = useState("");
    const [message, setMessage] = useState("");
    const [disableButton, setDisableButton] = useState(false);

    const unsubscribeReasons = [
        'Too many emails',
        'Irrelevant content',
        'Found another service/provider',
        'Privacy concerns',
        'Changed preferences',
        'Email fatigue',
        'Content duplication',
        'Unintentional subscription',
    ];

    let navigate = useNavigate();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const emailParam = urlParams.get('email');
        const nameParam = urlParams.get('name');
        if (!emailParam) navigate("/");
        setEmail(emailParam || '');
        setName(nameParam || '');
    }, []);

    async function handleUnsubscribe(e) {
        e.preventDefault();
        setDisableButton(true);
        const response = await sendEmail({ type: "unsubscribe", email, name, reason });

        if (response === 200) {
            setMessage("Successfully unsubscribed");
            setReason("");
        } else {
            setMessage("Unsuccessful unsubscribe, please try again later");
        }
        setDisableButton(false);
    }

    return (
        <>
            <Helmet>
                <title>Unsubscribe | WeBridge</title>
                <meta name="description" content="To unsubscribe from WeBridge'e newsletter" />
                <meta name="keyword" content="WeBridge, unsubscribe" />
            </Helmet>
            {email &&
                <div className={"unsubscribe-container"}>
                    <h1>We are sorry to see you leave ...</h1>
                    {/* <div> */}
                    <p>Your email address {email}</p>
                    <p>You will not receive any more newsletter emails from <a href="mailto:contact@windenergybridge.com" style={{ color: "var(--color-main)", textDecoration: "none" }}>contact@windenergybridge.com</a></p>
                    {/* </div> */}
                    <form onSubmit={handleUnsubscribe}>
                        <h2>Please choose a reason for unsubscribing:</h2>
                        <div>
                            <select value={reason} onChange={(e) => { setReason(e.target.value); }}>
                                <option value="">Select a reason...</option>
                                {unsubscribeReasons.map((unsubscribeReason) => (
                                    <option key={unsubscribeReason} value={unsubscribeReason}>
                                        {unsubscribeReason}
                                    </option>
                                ))}
                            </select>
                            <button type="submit" disabled={disableButton} style={{ opacity: disableButton ? "0.7" : "1" }}>
                                {disableButton ? <Spinner /> : "Unsubscribe"}
                            </button>
                        </div>
                        <p className='message'>{message}</p>
                    </form>
                </div>
            }
        </>
    );
}