import React, { useContext, useEffect, useState } from "react";
import './Header.scss';
import { NavLink, useLocation } from "react-router-dom";
import { weBridgeLogo } from "../../assets/assetReference";
import { GlobalContext } from "../../context/globalContext";

export default function Header() {
    const { isSmallScreen } = useContext(GlobalContext);
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const location = useLocation();

    function toggleMenu() {
        if (!menuIsOpen) {
            document.querySelector("html").style.overflow = "hidden";
        } else {
            document.querySelector("html").style.overflow = "visible";
        }

        setMenuIsOpen(!menuIsOpen);

    }

    function closeMenu() {
        document.querySelector("html").style.overflow = "visible";
        setMenuIsOpen(false);
    }

    function isSelected(path) {
        return location.pathname === path ? "selected" : "";
    }

    const [openServices, setOpenServices] = useState(false);
    return (
        <header className={"header-container"} onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }}>
            <div>
                <NavLink to="/" onClick={closeMenu}>
                    <img src={weBridgeLogo.red} alt="WeBridge logo in red" />
                </NavLink>
                {isSmallScreen && (
                    <span className="material-symbols-outlined menu-icon" onClick={toggleMenu}>
                        {menuIsOpen ? "close" : "menu"}
                    </span>
                )}

            </div>

            <ul className={`header-list ${isSmallScreen && menuIsOpen && "open"}`}>
                <li className={`header-item ${isSelected("/about-us")}`}>
                    <NavLink className="a-no-decor" onClick={closeMenu} to="/about-us">About Us</NavLink>
                </li>
                <li className={`header-item ${location.pathname.split("/")[1] === "services" ? "selected" : ""}`} onMouseEnter={() => { setOpenServices(true); }} onMouseLeave={() => { setOpenServices(false); }}>
                    {/* <NavLink className="a-no-decor" to="/services">Service</NavLink> */}
                    <p>Services</p>
                    {openServices &&
                        <ul className="services-container" onClick={() => { setOpenServices(false); }}>
                            <li className={`header-item ${isSelected("/services/for-developers")}`}>
                                <NavLink className="a-no-decor" onClick={closeMenu} to="/services/for-developers">For Developers</NavLink>
                            </li>
                            <li className={`header-item ${isSelected("/services/for-suppliers")}`}>
                                <NavLink className="a-no-decor" onClick={closeMenu} to="/services/for-suppliers">For Suppliers</NavLink>
                            </li>
                            <li className={`header-item ${isSelected("/services/for-investors")}`}>
                                <NavLink className="a-no-decor" onClick={closeMenu} to="/services/for-investors">For Investors</NavLink>
                            </li>
                            <li className={`header-item ${isSelected("/services/for-insurers")}`}>
                                <NavLink className="a-no-decor" onClick={closeMenu} to="/services/for-insurers">For Insurers</NavLink>
                            </li>
                        </ul>
                    }
                </li>

                <li className={`header-item ${isSelected("/insights")}`}>
                    <NavLink className="a-no-decor" onClick={closeMenu} to="/insights">Insights</NavLink>
                </li>
                <li className={`header-item ${isSelected("/work-for-us")}`}>
                    <NavLink className="a-no-decor" onClick={closeMenu} to="/work-for-us">Work for Us</NavLink>
                </li>
                <li className={`header-item ${isSelected("/contact-us")}`}>
                    <NavLink className="a-no-decor" onClick={closeMenu} to="/contact-us">Contact Us</NavLink>
                </li>
            </ul>
            {/* } */}
        </header>
    );
}