import Insight from "../insightClass";
import React from "react";
import { insight22Media } from "../../../assets/assetReference";
import { ContactSubscribe } from "../../../components/Contact/Contact";
import { NavLink } from "react-router-dom";

export const insight022 = new Insight(
  "12 November 2024",
  {
    pageTitle:
      "Abu Dhabi International Petroleum Exhibition & Conference, 4-7th November 2024",
    metaDescription:
      "WeBridge Insights, Abu Dhabi International Petroleum Exhibition & Conference, 4-7th November 2024",
    keyWords:
      "European Offshore Wind Market development, Abu Dhabi International Petroleum Exhibition & Conference, 4-7th November 2024",
  },
  { src: insight22Media[0], alt: "at Adu Dhabi international petroleum exhibition & conference" },
  "Abu Dhabi International Petroleum Exhibition & Conference, 4-7th November 2024",
  "",
  `At WeBridge we are focused on establishing the bridge between Asian suppliers and European developers. But midway we do find the Middle East, with its strategic central position. Here we can find suppliers for the offshore wind segment and maybe at some point also developers of offshore wind. However, for the foreseeable future, the onshore photovoltaic option is likely the winner.`,
  (
    <div>
      <strong>WeBridge Insights</strong>
      <h1>
        Abu Dhabi International Petroleum Exhibition & Conference, 4-7th November 2024
      </h1>
      <h2></h2>
      <p style={{ opacity: "0.4", marginTop: "-70px" }}>12 November 2024</p>

      <img src={insight22Media[0]}
        alt="at Adu Dhabi international petroleum exhibition & conference"
        style={{ marginBottom: "48px" }} />


      <p>At WeBridge we are focused on establishing the bridge between Asian suppliers and European developers. But midway we do find the Middle East, with its strategic central position. Here we can find suppliers for the offshore wind segment and maybe at some point also developers of offshore wind. However, for the foreseeable future, the onshore photovoltaic option is likely the winner.</p>


      <p>WeBridge is an optimistic company so we wanted to attend the Abu Dhabi International Petroleum Exhibition & Conference (aka ADIPEC) to see if we could explore below the surface and find some hidden opportunities. To be honest, the upstream- oriented oil & gas conference didn’t bring a high number of new connections and leads, but those we got were valuable.</p>


      <p>However, I would like to share some lessons learned for the offshore wind industry. Around ten years ago, the oil & gas industry entered a downturn. The offshore wind industry benefited from this with both the end clients and the suppliers moving into offshore wind, with its money and capacity driving prices down and the number of projects up. Now we can see a significant number of companies working in both industry segments.</p>


      <p>Unfortunately, during the last 12 months, more “operators” from the oil and gas industry have exited their “developer” positions and gone back to focus more on their core business, where the money to be made is bigger (and easier). The same is happening on the contractor side. Why chase projects with margins around 3%-6% in offshore when you can go after 8%-12% in the oil and gas segment?</p>


      <p>Walking around ADIPEC, I see no sign of the oil & gas industry being on its last legs. On the contrary, I see an industry striding forward like before. Of course, there will be geographical differences, but there is no doubt that the offshore wind industry will face fierce competition from the oil & gas industry for funding, equipment, and personnel. To stay competitive, the offshore wind industry needs to continue its firm drive forward. Developers cannot continue to ask for suppliers to take increased risk and lower margins. The suppliers need to continue to look for improvements. The individual governments need to facilitate this development with robust frameworks and, if needed, subsidies – especially for floating wind. If not, the developers and the suppliers will leave them, and the green transition will not take place.</p>


      <p>ADIPEC had a hall dedicated to the use of AI. Although not all of us have seen how AI can be integrated into our everyday routine, it is coming. There were several examples of AI driving costs down, for instance, through optimisation activities. We all need to take a closer look at how we can be at the forefront of this integration process if we want to stay competitive.</p>


      <p>On a final note, and perhaps as a warning to the European supply chain, and positive news for the developers: The Chinese supply chain is coming. Their presence at ADIPEC was indeed seen, and the experience they gain in the oil & gas industry will in the medium to long run make them more competitive in the offshore wind segment. However, it remains to see how the offshore wind industry can take a constructive approach and look for a win-win scenario for all parties.</p>

      <p>If you would like to find out more about the services WeBridge offer and how we could help with your offshore wind project, please <a href="https://windenergybridge.com/contact-us" target="_blank">contact us</a></p>







      <h3 style={{ marginTop: "200px" }}>Subscribe</h3>
      <ContactSubscribe />
    </div>
  )
);
